import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  useTheme
} from '@mui/material';

const TrafficByDevice = ({ status }) => {
  const theme = useTheme();
  const values = [];
  const labels = [];
  const colorPie = [];
  const devicesData = [];
  const colorStatus = [
    {
      status: 'Aguardando Orçamento',
      color: colors.grey[500]
    },
    {
      status: 'Orçamento Finalizado',
      color: colors.blue[500]
    },
    {
      status: 'Orçamento Aprovado',
      color: colors.green[500]
    },
    {
      status: 'Serviço Iniciado',
      color: colors.purple[500]
    },
    {
      status: 'Serviço Finalizado',
      color: colors.yellow[500]
    },
    {
      status: 'Aguardando Retirada',
      color: colors.pink[500]
    },
    {
      status: 'Order Finalizado',
      color: colors.green[500]
    },
    {
      status: 'Order Cancelado',
      color: colors.red[500]
    },
  ];

  // eslint-disable-next-line array-callback-return
  status.map((item) => {
    const totalItens = status.reduce((a, e) => a + e.amount, 0);
    const color = colorStatus.find((i) => i.status === item.status);
    values.push(item.amount);
    labels.push(item.status);
    colorPie.push(color.color);

    devicesData.push({
      title: item.status,
      value: ((item.amount / totalItens) * 100).toFixed(0),
      color: color.color
    });
  });

  const data = {
    datasets: [
      {
        data: values,
        backgroundColor: colorPie,
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const devices = devicesData;

  return (
    <Card>
      <CardHeader title="Status das Ordens" />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 300,
            position: 'relative'
          }}
        >
          <Doughnut
            data={data}
            options={options}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 2
          }}
        >
          {devices.map(({
            color,
            title,
            value
          }) => (
            <Box
              key={title}
              sx={{
                p: 1,
                textAlign: 'center'
              }}
            >
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {title}
              </Typography>
              <Typography
                style={{ color }}
                variant="h2"
              >
                {value}
                %
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

TrafficByDevice.propTypes = {
  status: PropTypes.array.isRequired,
};
export default TrafficByDevice;
