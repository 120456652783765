import {
  Box, CircularProgress, Container, Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@mui/material';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { OrderContext } from 'src/context/OrderContext';

const OrderReport = () => {
  const { id } = useParams();
  const { order, getOrderPublic } = useContext(OrderContext);

  const style = {
    headers: {
      backgroundColor: '#69AAF5',
      color: '#fff'
    },
    totalContainer: {
      border: '1px dotted silver'
    }
  };

  useEffect(async () => {
    await getOrderPublic(id);
  }, []);

  const groupField = (name, value) => (
    <>
      <Typography variant="h4" p={1}>
        {name}
        <Typography color="#7C8A9D" variant="h5">{value}</Typography>
      </Typography>
    </>
  );

  const clientData = () => (
    <>
      <Grid container>
        <Grid container>
          <Grid item xs={12}>
            <Typography p={1} style={style.headers} textAlign="center" variant="h3">CLIENTE</Typography>
          </Grid>
          <Grid item p={4} xs={6}>
            <Typography variant="h4">
              Nome
              <Typography color="#7C8A9D" variant="h5">{order.client.name}</Typography>
            </Typography>
          </Grid>
          <Grid item p={4} xs={6}>
            <Typography variant="h4">
              {order.client.documentTypePrimary.name}
              <Typography color="#7C8A9D" variant="h5">{order.client.document_primary}</Typography>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  const productData = () => (
    <>
      <Grid container>
        <Grid container>
          <Grid item xs={12}>
            <Typography p={1} style={style.headers} textAlign="center" variant="h3">PRODUTO</Typography>
          </Grid>
          <Grid item p={4} xs={12} lg={6} md={6}>
            <Box
              component="img"
              sx={{
                maxHeight: { xs: 203, md: 167 },
                maxWidth: { xs: 300, md: 250 },
              }}
              alt="The house from the offer."
              src={order.product.image}
            />
          </Grid>
          <Grid item p={4} xs={12} lg={6} md={6}>
            {groupField('Nome', order.product.name)}
            {groupField('Marca', order.product.brand.name)}
            {groupField('Modelo', order.product.model)}
            {groupField('Defeito apresentado', order.observation)}
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  // eslint-disable-next-line no-shadow
  const servicesData = () => (
    <>
      <Grid container>
        <Grid container>
          <Grid item xs={12}>
            <Typography p={1} style={style.headers} textAlign="center" variant="h3">SERVIÇOS</Typography>
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 100 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h3">
                      Serviço
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="h3">
                      Valor
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.services.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Typography color="#7C8A9D" variant="h5">
                        {row.service}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography color="#7C8A9D" variant="h5">
                        {`R$ ${row.value}`}
                      </Typography>

                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );

  const totalData = () => (
    <>
      <Grid container>
        <Grid container>
          <Grid item xs={12}>
            <Typography p={1} style={style.headers} textAlign="center" variant="h3">TOTAL</Typography>
          </Grid>
          <Grid container style={style.totalContainer} xs={12} p={2}>
            <Grid item xs={6}>
              <Typography variant="h4">
                Total Serviços
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign="right" variant="h4">
                R$
                {' '}
                {order.amount_services}
              </Typography>
            </Grid>
          </Grid>
          <Grid container style={style.totalContainer} xs={12} p={2}>
            <Grid item xs={6}>
              <Typography variant="h4">
                Custo Mão de Obra
              </Typography>
            </Grid>
            <Grid item textAlign="right" xs={6}>
              <Typography variant="h4">
                R$
                {' '}
                {order.labor}
              </Typography>
            </Grid>
          </Grid>
          <Grid container style={style.totalContainer} xs={12} p={2}>
            <Grid item xs={6}>
              <Typography variant="h4">
                Total
              </Typography>
            </Grid>
            <Grid item textAlign="right" xs={6}>
              <Typography variant="h4">
                R$
                {' '}
                {order.amount_services_labor}
              </Typography>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </>
  );

  const loading = () => (
    <>
      <Container maxWidth="lg">
        <Box p={40} justifyContent="center" sx={{ display: 'flex' }}>
          <Grid>
            <Grid item ml={6} xs={12}>
              <CircularProgress />
            </Grid>
            <Grid item sx={12} p={2}>
              <Typography variant="h4">
                Carregando
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME}
          {' - '}
          Ordem de Serviço
        </title>
      </Helmet>

      {!order
        ? loading()
        : (
          <Box
            sx={{
              backgroundColor: 'background.default',
              minHeight: '100%',
              py: 3
            }}
          >
            <Container maxWidth="lg">
              <Paper>
                <Typography p={4} textAlign="center" variant="h1">Ordem de Servico</Typography>
                <Typography p={1} textAlign="center" variant="h3">
                  #
                  {' '}
                  {id}
                </Typography>
                <Divider />
                {clientData()}
                <Divider />
                {productData()}
                <Divider />
                {servicesData()}
                <Divider />
                {totalData()}
              </Paper>
            </Container>
          </Box>
        )}
    </>
  );
};

export default OrderReport;
