import {
  React,
  useState,
  createContext,
  useContext
} from 'react';
import api from 'src/services/api';
import { SiteContext } from './SiteContext';

export const OrderServiceHistoryContext = createContext();

const OrderServiceHistoryProvider = (prop) => {
  const baseUrl = 'user/orderServiceHistory';
  const { children } = prop;
  const [status, setStatus] = useState([]);
  const {
    notifyMessage,
    setNotifyMessage,
    loading,
    setLoading
  } = useContext(SiteContext);

  const closeNotifyMessage = () => {
    setNotifyMessage({
      ...notifyMessage,
      open: false
    });
  };

  const notifyMessageSave = (action) => {
    setNotifyMessage({
      text: `Registro ${action} com Sucesso !`,
      type: 'success',
      open: true
    });
  };

  const notifyMessageError = (action, err) => {
    setNotifyMessage({
      text: `Erro ao ${action} =( ${err}`,
      type: 'error',
      open: true
    });
  };

  const getStatus = async () => {
    setLoading(true);
    await api.get(`${baseUrl}/status`)
      .then((response) => {
        setStatus(response.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar status', err);
        return false;
      });
  };

  return (
    <OrderServiceHistoryContext.Provider
      value={{
        status,
        loading,
        getStatus,
        closeNotifyMessage,
        notifyMessageError,
        notifyMessageSave
      }}
    >
      { children }
    </OrderServiceHistoryContext.Provider>

  );
};

export default OrderServiceHistoryProvider;
