import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Grid
} from '@mui/material';
import { Search as SearchIcon } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useState } from 'react';

const TechnicianListToolbar = (props) => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    search: '',
  });
  const { search } = props;

  const handleChange = async (event) => {
    setValues({
      [event.target.name]: event.target.value
    });
  };

  const handleSearch = async () => {
    await search(values.search);
  };

  const buttonNew = () => {
    navigate('/app/technician', { replace: true });
  };

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={buttonNew}
        >
          Adicionar Tecnico
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box>
              <Grid container>
                <Grid item xs={12} md={2} lg={10}>
                  <TextField
                    fullWidth
                    label="Pesquisar"
                    name="search"
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Pesquisar"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                  <Button fullWidth style={{ padding: 15, float: 'right' }} color="primary" variant="contained" onClick={handleSearch}><SearchIcon /></Button>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

TechnicianListToolbar.propTypes = {
  search: PropTypes.isRequired
};

export default TechnicianListToolbar;
