import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  crossDomain: true
});

/* eslint no-param-reassign: 2 */
api.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('authorization');
  if (token) {
    config.headers.Authorization = `Bearer ${sessionStorage.getItem('authorization')}`;
  }
  return config;
});

api.interceptors.response.use((response) => response,
  (error) => {
    if (error.response.status === 401) {
      sessionStorage.setItem('user', '');
      sessionStorage.setItem('authorization', '');
      const url = window.location.href.split('/');
      if (url[3] !== 'login' && url[3] !== '') {
        window.location.href = '/login/expiration';
      }
    }

    let listMessage = '';

    if (error.response.status === 400) {
      listMessage = [error.response.data];
      return Promise.reject(listMessage);
    }

    if (error.response && error.response.status === 422) {
      const errorData = error.response.data;

      if (errorData && errorData.message === 'The given data was invalid.') {
        let errorMessage = 'Erro de validação:\n';

        Object.keys(errorData.errors).forEach((field) => {
          const fieldErrors = errorData.errors[field];
          errorMessage += `${fieldErrors.join(', ')}\n`;
        });

        // Aqui, você pode decidir o que fazer com a mensagem de erro gerada
        return Promise.reject(errorMessage);
      }
    }

    if (error.response.data && error.response.status !== 400) {
      const erros = Object.values(error.response.data);
      erros.forEach((element) => {
        if (element !== 'undefined') {
          listMessage += element;
        }
      });
    } else if (error.response.data.message) {
      listMessage = [error.response.data.message];
    } else {
      listMessage = ['Erro ao conectar ao servidor, tente novamente mais tarde'];
    }
    return Promise.reject(listMessage);
  });

export default api;
