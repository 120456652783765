import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  TablePagination,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import {
  Edit as EditIcon,
  Trash2 as TrashIcon
} from 'react-feather';
import { useNavigate } from 'react-router-dom';
import DeleteModal from 'src/components/modal/DeleteModal';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
});

const TechnicianListResults = ({
  technicians,
  exclude,
  paginate,
}) => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const classes = useStyles();
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: null,
    text: 'Tem certeza que deseja excluir este Técnico ?',
  });

  const excludeTechnician = async (id) => {
    const confirm = await exclude(id);
    if (confirm) {
      setDeleteModal({
        ...deleteModal,
        open: false
      });
    }
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    paginate((newPage + 1));
  };

  const buttonEdit = (id) => {
    navigate(`/app/technician/${id}`, { replace: true });
  };

  const deleteTechnician = (id) => {
    setDeleteModal({
      ...deleteModal,
      id,
      open: true,
      confirm: excludeTechnician,
      cancel: () => { }
    });
  };

  return (
    <>
      <Box sx={{ minWidth: 1050 }}>
        <Grid
          container
          spacing={3}
        >
          {technicians.data.slice(0, limit).map((technician) => (
            <Grid item xs={12} md={3} lg={3}>
              <Card className={classes.root}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt={technician.name}
                    sx={1}
                    image={technician.image ? technician.image : '/image/no-image.png'}
                    title={technician.name}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {technician.name}
                    </Typography>
                    <Typography gutterBottom variant="h5" component="h2">
                      {technician.email}
                    </Typography>
                    <Typography gutterBottom variant="h5" component="h2">
                      {technician.phone}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button
                    onClick={() => { buttonEdit(technician.id); }}
                    title="Editar"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    <EditIcon />
                  </Button>
                  <Button
                    onClick={() => { deleteTechnician(technician.id); }}
                    title="Excluir"
                    fullWidth
                    variant="contained"
                    color="error"
                  >
                    <TrashIcon />
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <TablePagination
        component="div"
        count={technicians.meta.total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[]}
      />
      <DeleteModal modal={deleteModal} />
    </>
  );
};

TechnicianListResults.propTypes = {
  technicians: PropTypes.array.isRequired,
  exclude: PropTypes.array.isRequired,
  paginate: PropTypes.array.isRequired
};

export default TechnicianListResults;
