import { Helmet } from 'react-helmet';
import {
  Box, Container, Grid, Typography
} from '@mui/material';
import TechnicianListResults from 'src/components/technician/TechnicianListResults';
import TechnicianListToolbar from 'src/components/technician/TechnicianListToolbar';
import { TechnicianContext } from 'src/context/TechnicianContext';
import { useContext, useEffect } from 'react';

const TechnicianList = () => {
  const {
    technicians,
    getTechnicians,
    deleteTechnician,
    searchTechnicians,
    paginateTechnician,
  } = useContext(TechnicianContext);
  useEffect(() => {
    getTechnicians();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`Produtos | ${process.env.REACT_APP_NAME}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <TechnicianListToolbar search={searchTechnicians} />
          <Box sx={{ pt: 3 }}>
            {technicians.data && technicians.data.length > 0
              ? <TechnicianListResults technicians={technicians} exclude={deleteTechnician} paginate={paginateTechnician} />
              : (
                <>
                  <Grid container alignContent="center" justifyContent="center" alignItems="center" lg={12}>
                    <Typography variant="h3">
                      Nenhum registro encontrado
                    </Typography>
                  </Grid>
                  {' '}

                </>
              )}
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default TechnicianList;
