import {
  React,
  useState,
  createContext,
  useContext,
} from 'react';
import api from 'src/services/api';
import { SiteContext } from './SiteContext';

export const NotificationsContext = createContext();

const NotificationsProvider = (prop) => {
  const baseUrl = 'user/notifications';
  const { children } = prop;
  const [notifications, setNotifications] = useState([]);
  const {
    notifyMessage,
    setNotifyMessage,
    loading,
  } = useContext(SiteContext);

  const closeNotifyMessage = () => {
    setNotifyMessage({
      ...notifyMessage,
      open: false
    });
  };

  const notifyMessageSave = (action) => {
    setNotifyMessage({
      text: `Registro ${action} com Sucesso !`,
      type: 'success',
      open: true
    });
  };

  const notifyMessageError = (action, err) => {
    setNotifyMessage({
      text: `Erro ao ${action} =( ${err}`,
      type: 'error',
      open: true
    });
  };

  const getNotifications = async () => {
    await api.get(`${baseUrl}`)
      .then((response) => {
        setNotifications(response.data.data);
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  const setReadNotification = async (id) => {
    await api.patch(`${baseUrl}/setRead/${id}`)
      .then(async () => getNotifications())
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        loading,
        setReadNotification,
        setNotifications,
        getNotifications,
        setNotifyMessage,
        closeNotifyMessage,
        notifyMessageError,
        notifyMessageSave
      }}
    >
      { children }
    </NotificationsContext.Provider>

  );
};

export default NotificationsProvider;
