import { Outlet } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { Snackbar } from '@mui/material';
import MainNavbar from 'src/components/MainNavbar';
import { useContext } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { styled } from '@mui/system';
import { SiteContext } from 'src/context/SiteContext';
import { Helmet } from 'react-helmet';

const MainLayoutRoot = styled('div')(
  () => ({
    backgroundColor: '#F3F6F9',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const MainLayoutWrapper = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 64
});

const MainLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const MainLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const MainLayout = () => {
  const { notifyMessage, setNotifyMessage } = useContext(SiteContext);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotifyMessage({
      ...notifyMessage,
      open: false
    });
  };
  return (
    <MainLayoutRoot>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <MainNavbar />
      <MainLayoutWrapper>
        <MainLayoutContainer>
          <MainLayoutContent>
            <Outlet />
            <Snackbar
              open={notifyMessage.open}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert onClose={handleClose} severity={notifyMessage.type}>
                {notifyMessage.text}
              </Alert>
            </Snackbar>
          </MainLayoutContent>
        </MainLayoutContainer>
      </MainLayoutWrapper>
    </MainLayoutRoot>
  );
};

export default MainLayout;
