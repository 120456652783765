import { Helmet } from 'react-helmet';
import {
  Box, Container, Grid, Typography
} from '@mui/material';
import DocumentTypeListResults from 'src/components/documentType/DocumentTypeListResults';
import DocumentTypeListToolbar from 'src/components/documentType/DocumentTypeListToolbar';
import { DocumentTypeContext } from 'src/context/DocumentTypeContext';
import { useContext, useEffect } from 'react';

const DocumentTypeList = () => {
  const {
    documentTypes,
    getDocumentTypes,
    deleteDocumentType,
    searchDocumentTypes,
    paginateDocumentType,
  } = useContext(DocumentTypeContext);
  useEffect(() => {
    getDocumentTypes();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`Tipo de Documentos | ${process.env.REACT_APP_NAME}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <DocumentTypeListToolbar search={searchDocumentTypes} />
          <Box sx={{ pt: 3 }}>
            {documentTypes.data && documentTypes.data.length > 0
              ? <DocumentTypeListResults documentTypes={documentTypes} exclude={deleteDocumentType} paginate={paginateDocumentType} />
              : (
                <>
                  <Grid container alignContent="center" justifyContent="center" alignItems="center" lg={12}>
                    <Typography variant="h3">
                      Nenhum registro encontrado
                    </Typography>
                  </Grid>
                </>
              )}
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default DocumentTypeList;
