import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import ClientList from 'src/pages/ClientList';
import ClientForm from 'src/pages/ClientForm';
import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import ProductList from 'src/pages/ProductList';
import ProductForm from 'src/pages/ProductForm';
import TechnicianList from 'src/pages/TechnicianList';
import TechnicianForm from 'src/pages/TechnicianForm';
import OrderList from 'src/pages/OrderList';
import OrderForm from 'src/pages/OrderForm';
import SiteProvider from 'src/context/SiteContext';
import ClientProvider from 'src/context/ClientContext';
import DocumentTypeProvider from 'src/context/DocumentTypeContext';
import ProductProvider from 'src/context/ProductContext';
import OrderProvider from 'src/context/OrderContext';
import TechnicianProvider from 'src/context/TechnicianContext';
import OrderServiceProvider from 'src/context/OrderServiceContext';
import OrderServiceHistoryProvider from 'src/context/OrderServiceHistoryContext';
import UserList from 'src/pages/UserList';
import UserForm from 'src/pages/UserForm';
import UserProvider from 'src/context/UserContext';
import DocumentTypeList from './pages/DocumentTypeList';
import DocumentTypeForm from './pages/DocumentTypeForm';
import DashboardProvider from './context/DashboardContex';
import ConfigForm from './pages/ConfigForm';
import ConfigProvider from './context/ConfigContext';
import NotificationsProvider from './context/NotificationsContext';
import ChangePassword from './pages/ChangePassword';
import OrderReport from './pages/OrderReport';

const routes = [
  {
    path: 'app',
    element:
  <SiteProvider>
    <ClientProvider>
      <TechnicianProvider>
        <DocumentTypeProvider>
          <ProductProvider>
            <OrderProvider>
              <OrderServiceProvider>
                <OrderServiceHistoryProvider>
                  <UserProvider>
                    <ConfigProvider>
                      <NotificationsProvider>
                        <DashboardProvider>
                          <DashboardLayout />
                        </DashboardProvider>
                      </NotificationsProvider>
                    </ConfigProvider>
                  </UserProvider>
                </OrderServiceHistoryProvider>
              </OrderServiceProvider>
            </OrderProvider>
          </ProductProvider>
        </DocumentTypeProvider>
      </TechnicianProvider>
    </ClientProvider>
  </SiteProvider>,
    children: [
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'config', element: <ConfigForm /> },
      { path: 'documentTypes', element: <DocumentTypeList /> },
      { path: 'documentType/:id', element: <DocumentTypeForm /> },
      { path: 'documentType/', element: <DocumentTypeForm /> },
      { path: 'clients', element: <ClientList /> },
      { path: 'client/:id', element: <ClientForm /> },
      { path: 'client/', element: <ClientForm /> },
      { path: 'products', element: <ProductList /> },
      { path: 'product', element: <ProductForm /> },
      { path: 'product/:id', element: <ProductForm /> },
      { path: 'product/client/:clientId', element: <ProductForm /> },
      { path: 'orders', element: <OrderList /> },
      { path: 'orders/status/:filterStatus', element: <OrderList /> },
      { path: 'order', element: <OrderForm /> },
      { path: 'order/:id', element: <OrderForm /> },
      { path: 'order/product/:productId', element: <OrderForm /> },
      { path: 'technicians', element: <TechnicianList /> },
      { path: 'technician/:id', element: <TechnicianForm /> },
      { path: 'technician/', element: <TechnicianForm /> },
      { path: 'users', element: <UserList /> },
      { path: 'user/:id', element: <UserForm /> },
      { path: 'user/', element: <UserForm /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element:
  <SiteProvider>
    <UserProvider>
      <OrderProvider>
        <MainLayout />
      </OrderProvider>
    </UserProvider>
  </SiteProvider>,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'changePassword/:token', element: <ChangePassword /> },
      { path: 'login/:expiration', element: <Login /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '/order/:id', element: <OrderReport /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
