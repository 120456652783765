import {
  React,
  useState,
  createContext,
  useContext
} from 'react';
import api from 'src/services/api';
import { SiteContext } from './SiteContext';

export const UserContext = createContext();

const UserProvider = (prop) => {
  const baseUrl = 'user/user';
  const { children } = prop;
  const [user, setUser] = useState(null);
  const [logedUser, setLogedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [usersAll, setUsersAll] = useState([]);
  const [urlPaginate, setUrlPaginate] = useState();
  const {
    notifyMessage,
    setNotifyMessage,
    loading,
    setLoading
  } = useContext(SiteContext);
  const [search, setSearch] = useState();

  const closeNotifyMessage = () => {
    setNotifyMessage({
      ...notifyMessage,
      open: false
    });
  };

  const notifyMessageSave = (action) => {
    setNotifyMessage({
      text: `Registro ${action} com Sucesso !`,
      type: 'success',
      open: true
    });
  };

  const notifyMessageError = (action, err) => {
    setNotifyMessage({
      text: `Erro ao ${action} =( ${err}`,
      type: 'error',
      open: true
    });
  };

  const getUsers = async () => {
    setLoading(true);
    await api.get(baseUrl)
      .then((response) => {
        setUsers(response.data);
        setUrlPaginate(response.data.meta.path);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar', err);
        return false;
      });
  };

  const getAllUsers = async () => {
    setLoading(true);
    await api.get(`${baseUrl}/nopaginate`)
      .then((response) => {
        setUsersAll(response.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar todos', err);
        return false;
      });
  };

  const getUser = async (id) => {
    setLoading(true);
    await api.get(`${baseUrl}/${id}`)
      .then((response) => {
        setUser(response.data.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar', err);
        return false;
      });
  };

  const getUserByToken = async () => {
    setLoading(true);
    await api.get(`${baseUrl}/get/byToken`)
      .then((response) => {
        setLogedUser(response.data.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar por token', err);
        return false;
      });
  };

  const loginUser = async (data) => {
    setLoading(true);
    const serverResponse = await api.post('user/login', data)
      .then(async (response) => {
        sessionStorage.setItem('authorization', response.data.token);
        sessionStorage.setItem('user', response.data.user);
        await getUserByToken();
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Login', err);
        return false;
      });
    return serverResponse;
  };

  const saveUser = async (data) => {
    setLoading(true);
    const serverResponse = await api.post(baseUrl, data)
      .then((response) => {
        setUser(response.data.data);
        setLoading(false);
        notifyMessageSave('Salvo');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Salvar', err);
        return false;
      });
    return serverResponse;
  };

  const forgotPassword = async (data) => {
    setLoading(true);
    const serverResponse = await api.post('user/forgot', data)
      .then(() => {
        setLoading(false);
        setNotifyMessage({
          text: 'E-mail enviado com sucesso !',
          type: 'success',
          open: true
        });
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Salvar', err);
        return false;
      });
    return serverResponse;
  };

  const changePassword = async (data) => {
    setLoading(true);
    const serverResponse = await api.post('user/changePassword', data)
      .then(() => true)
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Salvar', err);
        return false;
      });
    return serverResponse;
  };

  const updateUser = async (id, data) => {
    setLoading(true);
    await api.post(`${baseUrl}/${id}`, data)
      .then((response) => {
        setUser(response.data.data);
        setLoading(false);
        notifyMessageSave('Atualizado');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Atualizar', err);
        return false;
      });
  };

  const searchUsers = async (param) => {
    setLoading(true);
    if (!param) {
      await getUsers();
    } else {
      await api.post(`${baseUrl}/search`, { param })
        .then((response) => {
          setUsers(response.data);
          setUrlPaginate(response.data.meta.path);
          setSearch(param);
          setLoading(false);
        })
        .catch((err) => {
          setUsers([]);
          notifyMessageError('Pesquisar', err);
          console.log(err);
        });
    }
  };

  const paginateUser = async (number) => {
    setLoading(true);
    if (search) {
      await api.post(`${urlPaginate}?page=${number}`, { search })
        .then((response) => {
          setUsers(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setUsers([]);
          setLoading(false);
          notifyMessageError('Pesquisar', err);
        });
    } else {
      await api.get(`${urlPaginate}?page=${number}`)
        .then((response) => {
          setUsers(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setUsers([]);
          setLoading(false);
          notifyMessageError('Pesquisar', err);
        });
    }
  };

  const deleteUser = async (id) => {
    setLoading(true);
    const response = await api.delete(`${baseUrl}/${id}`)
      .then(() => {
        setLoading(false);
        notifyMessageSave('excluido');
        getUsers();
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Excluir', err);
        return false;
      });
    return response;
  };

  return (
    <UserContext.Provider
      value={{
        user,
        users,
        usersAll,
        logedUser,
        loading,
        urlPaginate,
        getUsers,
        saveUser,
        updateUser,
        setUser,
        deleteUser,
        paginateUser,
        getUser,
        changePassword,
        forgotPassword,
        getUserByToken,
        setLogedUser,
        getAllUsers,
        setNotifyMessage,
        loginUser,
        searchUsers,
        closeNotifyMessage,
        notifyMessageError,
        notifyMessageSave
      }}
    >
      { children }
    </UserContext.Provider>

  );
};

export default UserProvider;
