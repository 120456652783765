import {
  React,
  useState,
  createContext,
  useContext
} from 'react';
import api from 'src/services/api';
import { SiteContext } from './SiteContext';

export const DocumentTypeContext = createContext();

const DocumentTypeProvider = (prop) => {
  const baseUrl = 'user/documentType';
  const { children } = prop;
  const [documentType, setDocumentType] = useState(null);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [documentTypesAll, setDocumentTypesAll] = useState([]);
  const [urlPaginate, setUrlPaginate] = useState();
  const {
    notifyMessage,
    setNotifyMessage,
    loading,
    setLoading
  } = useContext(SiteContext);
  const [search, setSearch] = useState();

  const closeNotifyMessage = () => {
    setNotifyMessage({
      ...notifyMessage,
      open: false
    });
  };

  const notifyMessageSave = (action) => {
    setNotifyMessage({
      text: `Registro ${action} com Sucesso !`,
      type: 'success',
      open: true
    });
  };

  const notifyMessageError = (action, err) => {
    setNotifyMessage({
      text: `Erro ao ${action} =( ${err}`,
      type: 'error',
      open: true
    });
  };

  const getDocumentTypes = async () => {
    setLoading(true);
    await api.get(baseUrl)
      .then((response) => {
        setDocumentTypes(response.data);
        setUrlPaginate(response.data.meta.path);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualziar todos', err);
        return false;
      });
  };

  const getAllDocumentTypes = async () => {
    setLoading(true);
    await api.get(`${baseUrl}/nopaginate`)
      .then((response) => {
        setDocumentTypesAll(response.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualziar todos', err);
        return false;
      });
  };

  const getDocumentType = async (id) => {
    setLoading(true);
    await api.get(`${baseUrl}/${id}`)
      .then((response) => {
        setDocumentType(response.data.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar', err);
        return false;
      });
  };

  const searchDocumentTypes = async (param) => {
    setLoading(true);
    if (!param) {
      await getDocumentTypes();
    } else {
      await api.post(`${baseUrl}/search`, { param })
        .then((response) => {
          setDocumentTypes(response.data);
          setUrlPaginate(response.data.meta.path);
          setSearch(param);
          setLoading(false);
        })
        .catch((err) => {
          setDocumentTypes([]);
          notifyMessageError('Pesquisar', err);
          console.log(err);
        });
    }
  };

  const paginateDocumentType = async (number) => {
    setLoading(true);
    if (search) {
      await api.post(`${urlPaginate}?page=${number}`, { search })
        .then((response) => {
          setDocumentTypes(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setDocumentTypes([]);
          setLoading(false);
          console.log(err);
        });
    } else {
      await api.get(`${urlPaginate}?page=${number}`)
        .then((response) => {
          setDocumentTypes(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setDocumentTypes([]);
          notifyMessageError('Paginar', err);
          console.log(err);
        });
    }
  };

  const saveDocumentType = async (data) => {
    setLoading(true);
    const serverResponse = await api.post(baseUrl, data)
      .then((response) => {
        setDocumentType(response.data.data);
        setLoading(false);
        notifyMessageSave('Salvo');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Salvar', err);
        return false;
      });
    return serverResponse;
  };

  const updateDocumentType = async (id, data) => {
    setLoading(true);
    await api.post(`${baseUrl}/${id}`, data)
      .then((response) => {
        setDocumentType(response.data.data);
        setLoading(false);
        notifyMessageSave('Atualizar');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Atualizar', err);
        return false;
      });
  };

  const deleteDocumentType = async (id) => {
    setLoading(true);
    const response = await api.delete(`${baseUrl}/${id}`)
      .then(() => {
        setLoading(false);
        notifyMessageSave('excluido');
        getDocumentTypes();
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Excluir', err);
        return false;
      });
    return response;
  };

  return (
    <DocumentTypeContext.Provider
      value={{
        documentType,
        documentTypes,
        loading,
        urlPaginate,
        documentTypesAll,
        getDocumentTypes,
        setDocumentType,
        saveDocumentType,
        updateDocumentType,
        deleteDocumentType,
        paginateDocumentType,
        getAllDocumentTypes,
        getDocumentType,
        setNotifyMessage,
        searchDocumentTypes,
        closeNotifyMessage,
        notifyMessageError,
        notifyMessageSave
      }}
    >
      { children }
    </DocumentTypeContext.Provider>

  );
};

export default DocumentTypeProvider;
