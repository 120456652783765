import { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import DashboardSidebar from 'src/components/DashboardSidebar';
import { SiteContext } from 'src/context/SiteContext';
import {
  Backdrop,
  CircularProgress,
  Snackbar
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { UserContext } from 'src/context/UserContext';
// eslint-disable-next-line import/no-extraneous-dependencies
import { styled } from '@mui/system';
import DashboardNavbar from './DashboardNavbar';

const DashboardLayoutRoot = styled('div')(
  () => ({
    backgroundColor: '#F3F6F9',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const DashboardLayoutWrapper = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  })
);

const DashboardLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const DashboardLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { notifyMessage, setNotifyMessage, loading } = useContext(SiteContext);
  const { getUserByToken } = useContext(UserContext);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotifyMessage({
      ...notifyMessage,
      open: false
    });
  };

  useEffect(() => {
    getUserByToken();
  }, []);

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <DashboardSidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
            <Snackbar
              open={notifyMessage.open}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert onClose={handleClose} severity={notifyMessage.type}>
                {notifyMessage.text}
              </Alert>
            </Snackbar>
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
      <Backdrop style={{ zIndex: 9999, color: '#fff' }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
