import {
  Card,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Eye as EyeIcon,
} from 'react-feather';

const LatestProducts = (props) => {
  const navigate = useNavigate();
  const { products } = props;

  const viewProduct = (id) => {
    navigate(`/app/product/${id}`, { replace: true });
  };

  return (
    <Card {...props}>
      <CardHeader
        subtitle={`${products.length} in total`}
        title="Útimos Produtos"
      />
      <Divider />
      <List>
        {products.map((product, i) => (
          <ListItem
            divider={i < products.length - 1}
            key={product.id}
          >
            <ListItemAvatar>
              <img
                alt={product.name}
                src={product.image ? product.image : '/image/no-image.png'}
                style={{
                  height: 48,
                  width: 48
                }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={product.name}
            />
            <IconButton
              edge="end"
              size="small"
              onClick={() => { viewProduct(product.id); }}
            >
              <EyeIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};
LatestProducts.propTypes = {
  products: PropTypes.array.isRequired,
};

export default LatestProducts;
