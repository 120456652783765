import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import getInitials from 'src/utils/getInitials';
import ConfirmModal from '../modal/ConfirmModal';

const ClientProfileDetails = (props) => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    image: '',
    name: '',
    type: '',
    document_type_id: '',
    document: '',
    zip_code: '',
    state: '',
    city: '',
    neighborhood: '',
    street: '',
    number: '',
    complement: ''
  });

  const [image, setImage] = useState(null);

  const handleChangeFile = (event) => {
    setImage(event.target.files[0]);
    setValues({
      ...values,
      image: URL.createObjectURL(event.target.files[0])
    });
  };

  const [confirmModal, setConfirmModal] = useState({
    open: false,
    title: '',
    description: '',
  });

  const types = [
    { key: 'P', label: 'Física' },
    { key: 'J', label: 'Jurídica' }
  ];

  const {
    documenttypes,
    client,
    restore,
    reload,
    saveform,
    updateform,
  } = props;

  useEffect(() => {
    if (client) {
      setValues({
        name: client.name,
        type: client.type,
        image: client.image,
        document_type_id: client.documentType ? client.documentType.id : '',
        document: client.document,
        zip_code: client.zip_code,
        state: client.state,
        city: client.city,
        neighborhood: client.neighborhood,
        street: client.street,
        number: client.number,
        complement: client.complement
      });
    }
  }, []);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleRestore = async () => {
    await restore(client.id);
    await reload();
    setConfirmModal({
      ...confirmModal,
      open: false
    });
  };

  const handleActiveClient = () => {
    setConfirmModal({
      open: true,
      title: 'Reativar Cliente',
      description: 'Tem certeza que deseja reativar este cliente ?',
      cancel: () => { },
      confirm: () => { handleRestore(); }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    if (image) {
      formData.append('image', image);
    } else {
      formData.delete('image');
    }

    if (client) {
      await updateform(client.id, formData);
    } else {
      await saveform(formData);
    }
    navigate('/app/clients', { replace: true });
  };

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit}
      {...props}
    >
      {client && client.deleted
         && (
         <Alert severity="error">
           {' '}
           Este cliente está excluido, para ativar ele novamente clique aqui
           {' '}
           <Button onClick={handleActiveClient}> Ativar</Button>
           {' '}
         </Alert>
         )}
      <Card>
        <CardHeader
          subheader="Dados do Cliente"
          title="Perfil"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <Avatar
                sx={{ width: 100, height: 100 }}
                lg={{ width: 200, height: 200 }}
                src={values.image}
              >
                {getInitials(values.name)}
              </Avatar>
              <Typography>Imagem Atual</Typography>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                name="image"
                onChange={handleChangeFile}
                type="file"
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nome"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="Tipo de Cadastro"
                name="type"
                onChange={handleChange}
                select
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{ native: true }}
                value={values.type}
                variant="outlined"
              >
                <option value="">Selecione</option>
                {types.map((option) => (
                  <option
                    key={option.key}
                    value={option.key}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="Tipo de Documento"
                name="document_type_id"
                onChange={handleChange}
                select
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{ native: true }}
                value={values.document_type_id}
                variant="outlined"
              >
                <option value="">Selecione</option>
                {documenttypes.data.map((option) => (
                  <option
                    key={option.id}
                    value={option.id}
                  >
                    {option.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="Documento"
                name="document"
                onChange={handleChange}
                value={values.document}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <Typography variant="h1" component="h2">
                Endereço
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="CEP"
                name="zip_code"
                onChange={handleChange}
                value={values.zip_code}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Estado"
                name="state"
                onChange={handleChange}
                value={values.state}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Cidade"
                name="city"
                onChange={handleChange}
                value={values.city}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Rua"
                name="street"
                onChange={handleChange}
                value={values.street}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Bairro"
                name="neighborhood"
                onChange={handleChange}
                value={values.neighborhood}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Numero"
                name="number"
                onChange={handleChange}
                value={values.number}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Complemento"
                name="complement"
                onChange={handleChange}
                value={values.complement}
                variant="outlined"
              />
            </Grid>

          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            Salvar
          </Button>
        </Box>
      </Card>
      <ConfirmModal modal={confirmModal} />
    </form>
  );
};

ClientProfileDetails.propTypes = {
  documenttypes: PropTypes.object,
  client: PropTypes.object,
  updateform: PropTypes.func.isRequired,
  saveform: PropTypes.func.isRequired,
  restore: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired,
};

export default ClientProfileDetails;
