import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { indigo } from '@mui/material/colors';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const TotalProfit = (props) => {
  const { value } = props;
  return (
    <Card {...props}>
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              Total para Receber
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              R$
              {' '}
              {value.amount}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: indigo[600],
                height: 56,
                width: 56
              }}
            >
              <AttachMoneyIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TotalProfit.propTypes = {
  value: PropTypes.array.isRequired,
};
export default TotalProfit;
