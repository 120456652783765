import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { green } from '@mui/material/colors';
import MoneyIcon from '@material-ui/icons/Money';
import { useNavigate } from 'react-router-dom';

const TotalCustomers = (props) => {
  const navigate = useNavigate();
  const { value } = props;
  const viewOrders = () => {
    navigate('/app/orders/status/E', { replace: true });
  };
  return (
    <Card {...props}>
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              Ordens em Execução
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {value.executing}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: green[600],
                height: 56,
                width: 56
              }}
            >
              <MoneyIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box
          sx={{
            pt: 2,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Button onClick={viewOrders}>Ver todas</Button>
        </Box>
      </CardContent>
    </Card>
  );
};

TotalCustomers.propTypes = {
  value: PropTypes.array.isRequired,
};
export default TotalCustomers;
