import {
  React,
  useState,
  createContext,
  useContext
} from 'react';
import api from 'src/services/api';
import { SiteContext } from './SiteContext';

export const ClientContext = createContext();

const ClientProvider = (prop) => {
  const baseUrl = 'user/client';
  const { children } = prop;
  const [client, setClient] = useState(null);
  const [clients, setClients] = useState([]);
  const [clientsAll, setClientsAll] = useState([]);
  const [urlPaginate, setUrlPaginate] = useState();
  const {
    notifyMessage,
    setNotifyMessage,
    loading,
    setLoading
  } = useContext(SiteContext);
  const [search, setSearch] = useState();

  const closeNotifyMessage = () => {
    setNotifyMessage({
      ...notifyMessage,
      open: false
    });
  };

  const notifyMessageSave = (action) => {
    setNotifyMessage({
      text: `Registro ${action} com Sucesso !`,
      type: 'success',
      open: true
    });
  };

  const notifyMessageError = (action, err) => {
    setNotifyMessage({
      text: `Erro ao ${action} =( ${err}`,
      type: 'error',
      open: true
    });
  };

  const getClients = async () => {
    setLoading(true);
    await api.get(baseUrl)
      .then((response) => {
        setClients(response.data);
        setUrlPaginate(response.data.meta.path);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar', err);
        return false;
      });
  };

  const getAllClients = async () => {
    setLoading(true);
    await api.get(`${baseUrl}/nopaginate`)
      .then((response) => {
        setClientsAll(response.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar todos', err);
        return false;
      });
  };

  const getClient = async (id) => {
    setLoading(true);
    await api.get(`${baseUrl}/${id}`)
      .then((response) => {
        setClient(response.data.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar', err);
        return false;
      });
  };

  const saveClient = async (data) => {
    setLoading(true);
    const serverResponse = await api.post(baseUrl, data)
      .then((response) => {
        setClient(response.data.data);
        setLoading(false);
        notifyMessageSave('Salvo');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Salvar', err);
        return false;
      });
    return serverResponse;
  };

  const updateClient = async (id, data) => {
    setLoading(true);
    await api.post(`${baseUrl}/${id}`, data)
      .then((response) => {
        setClient(response.data.data);
        setLoading(false);
        notifyMessageSave('Atualizado');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Atualizar', err);
        return false;
      });
  };

  const searchClients = async (param) => {
    setLoading(true);
    if (!param) {
      await getClients();
    } else {
      await api.post(`${baseUrl}/search`, param)
        .then((response) => {
          setClients(response.data);
          setUrlPaginate(response.data.meta.path);
          setSearch(param);
          setLoading(false);
        })
        .catch((err) => {
          setClients([]);
          setLoading(false);
          notifyMessageError('Pesquisar', err);
        });
    }
  };

  const paginateClient = async (number) => {
    setLoading(true);
    if (search) {
      await api.post(`${urlPaginate}?page=${number}`, { search })
        .then((response) => {
          setClients(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setClients([]);
          setLoading(false);
          notifyMessageError('Paginar', err);
        });
    } else {
      await api.get(`${urlPaginate}?page=${number}`)
        .then((response) => {
          setClients(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setClients([]);
          setLoading(false);
          notifyMessageError('Paginar', err);
        });
    }
  };

  const deleteClient = async (id) => {
    setLoading(true);
    const response = await api.delete(`${baseUrl}/${id}`)
      .then(() => {
        setLoading(false);
        notifyMessageSave('excluido');
        getClients();
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Excluir', err);
        return false;
      });
    return response;
  };

  const restoreClient = async (id) => {
    setLoading(true);
    const response = await api.patch(`${baseUrl}/restore/${id}`)
      .then(() => {
        setLoading(false);
        notifyMessageSave('Ativado');
        getClients();
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Excluir', err);
        return false;
      });
    return response;
  };

  const getClientWhatsappContact = async (id) => {
    setLoading(true);
    const data = await api.get(`${baseUrl}Contact/whatsapp/${id}`)
      .then((response) => {
        setLoading(false);
        return response.data.data;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar contato', err);
        return false;
      });

    return data;
  };

  const getClientContact = async (id) => {
    setLoading(true);
    const data = await api.get(`${baseUrl}Contact/${id}`)
      .then((response) => {
        setLoading(false);
        return response.data.data;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar contato', err);
        return false;
      });

    return data;
  };

  const saveClientContact = async (data) => {
    setLoading(true);
    const serverResponse = await api.post(`${baseUrl}Contact`, data)
      .then(() => {
        setLoading(false);
        getClient(client.id);
        notifyMessageSave('Salvo');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Salvar contato', err);
        return false;
      });
    return serverResponse;
  };

  const updateClientContact = async (id, data) => {
    setLoading(true);
    await api.post(`${baseUrl}Contact/${id}`, data)
      .then(() => {
        setLoading(false);
        getClient(client.id);
        notifyMessageSave('Atualizado');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Atualizar contato', err);
        return false;
      });
  };

  const deleteClientContact = async (id) => {
    setLoading(true);
    const response = await api.delete(`${baseUrl}Contact/${id}`)
      .then(() => {
        setLoading(false);
        getClient(client.id);
        notifyMessageSave('excluido');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Excluir contato', err);
        return false;
      });
    return response;
  };

  return (
    <ClientContext.Provider
      value={{
        client,
        clients,
        clientsAll,
        loading,
        urlPaginate,
        getClients,
        saveClient,
        updateClient,
        setClient,
        restoreClient,
        deleteClient,
        paginateClient,
        getClient,
        getClientContact,
        saveClientContact,
        updateClientContact,
        deleteClientContact,
        getAllClients,
        setNotifyMessage,
        searchClients,
        closeNotifyMessage,
        notifyMessageError,
        notifyMessageSave,
        getClientWhatsappContact
      }}
    >
      { children }
    </ClientContext.Provider>

  );
};

export default ClientProvider;
