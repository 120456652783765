import { Helmet } from 'react-helmet';
import {
  AppBar,
  Box,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import ConfigProfileDetails from 'src/components/config/ConfigProfileDetails';
import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ConfigContext } from 'src/context/ConfigContext';

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const tab = {
  color: '#FFF'
};

const tabSelected = {
  color: '#FFF',
  borderBottom: '5px solid red'
};

const ConfigForm = () => {
  const {
    config,
    getConfig,
    updateConfig,
  } = useContext(ConfigContext);

  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(async () => {
    await getConfig(1);
  }, []);

  useEffect(() => {
    if (config) {
      setLoading(false);
    }
  }, [config]);

  return (
    <>
      <Helmet>
        <title>
          {`Marca | ${process.env.REACT_APP_NAME}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          {loading === false
            ? (
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                      <Tab style={value === 0 ? tabSelected : tab} label="Configurações" {...a11yProps(0)} />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={value} index={0} style={{ backgroundColor: '#fff' }}>
                    <ConfigProfileDetails
                      config={config}
                      updateform={updateConfig}
                    />
                  </TabPanel>
                </Grid>
              </Grid>
            )
            : <></> }
        </Container>
      </Box>
    </>
  );
};
export default ConfigForm;
