import { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  TablePagination,
  Typography,
} from '@mui/material';
import { makeStyles } from '@material-ui/styles';

import {
  Edit as EditIcon,
  Trash2 as TrashIcon,
  Package as PackageIcon,
  Codesandbox as CodesandboxtIcon,
  Users as UsersIcon
} from 'react-feather';

import { useNavigate } from 'react-router-dom';
import DeleteModal from 'src/components/modal/DeleteModal';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
});

const ProductListResults = ({
  products,
  exclude,
  paginate,
  ...rest
}) => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const classes = useStyles();
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: null,
    text: 'Tem certeza que deseja excluir este Produto ?',
  });

  const excludeProduct = async (id) => {
    const confirm = await exclude(id);
    if (confirm) {
      setDeleteModal({
        ...deleteModal,
        open: false
      });
    }
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    paginate((newPage + 1));
  };

  const buttonEdit = (id) => {
    navigate(`/app/product/${id}`, { replace: true });
  };

  const buttonOrder = (id) => {
    navigate(`/app/order/product/${id}`, { replace: true });
  };

  const deleteProduct = (id) => {
    setDeleteModal({
      ...deleteModal,
      id,
      open: true,
      confirm: excludeProduct,
      cancel: () => { }
    });
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box>
          <Grid
            container
            spacing={3}
          >
            {products.data.slice(0, limit).map((product) => (
              <Grid item xs={12} md={3} lg={3}>
                <Card className={classes.root}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt={product.name}
                      sx={1}
                      image={product.image ? product.image : '/image/no-image.png'}
                      title={product.name}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {`${product.name} - ${product.model}`}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="h2">
                        <UsersIcon style={{ marginRight: 15 }} />
                        {product.client ? product.client.name : (
                          <Chip
                            size="small"
                            label="Cliente Excluido"
                            color="secondary"
                          />
                        )}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="h2">
                        <PackageIcon style={{ marginRight: 15 }} />
                        {product.brand}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="h2">
                        <CodesandboxtIcon style={{ marginRight: 15 }} />
                        {product.internal_code}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      onClick={() => { buttonEdit(product.id); }}
                      title="Editar"
                      fullWidth
                      variant="contained"
                      color="primary"
                    >
                      <EditIcon />
                    </Button>
                    <Button
                      onClick={() => { buttonOrder(product.id); }}
                      title="Abrir Ordem"
                      fullWidth
                      variant="contained"
                      color="success"
                    >
                      <PackageIcon color="#fff" />
                    </Button>
                    <Button
                      onClick={() => { deleteProduct(product.id); }}
                      title="Excluir"
                      fullWidth
                      variant="contained"
                      color="error"
                    >
                      <TrashIcon />
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </PerfectScrollbar>
      {products && products.meta.total > limit
      && (
      <TablePagination
        component="div"
        count={products.meta.total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[]}
      />
      )}
      <DeleteModal modal={deleteModal} />
    </Card>
  );
};

ProductListResults.propTypes = {
  products: PropTypes.array.isRequired,
  exclude: PropTypes.array.isRequired,
  paginate: PropTypes.array.isRequired
};

export default ProductListResults;
