import {
  React,
  useState,
  createContext,
  useContext
} from 'react';
import api from 'src/services/api';
import { SiteContext } from './SiteContext';

export const DashboardContext = createContext();

const DashboardProvider = (prop) => {
  const baseUrl = 'user/dashboard';
  const { children } = prop;
  const [statusPie, setPie] = useState([]);
  const [status, setStatus] = useState({
    waiting: 0,
    executing: 0,
    ready: 0,
    amount: 0
  });
  const [lastOrders, setLastOrders] = useState([]);
  const [expiredOrders, setExpiredOrders] = useState([]);
  const [lastProducts, setLastProducts] = useState([]);
  const {
    notifyMessage,
    setNotifyMessage,
    setLoading
  } = useContext(SiteContext);

  const closeNotifyMessage = () => {
    setNotifyMessage({
      ...notifyMessage,
      open: false
    });
  };

  const notifyMessageSave = (action) => {
    setNotifyMessage({
      text: `Registro ${action} com Sucesso !`,
      type: 'success',
      open: true
    });
  };

  const notifyMessageError = (action, err) => {
    setNotifyMessage({
      text: `Erro ao ${action} =( ${err}`,
      type: 'error',
      open: true
    });
  };

  const getStatusPie = async () => {
    await api.get(`${baseUrl}/getStatusPie`)
      .then((response) => {
        setPie(response.data.data);
        return true;
      })
      .catch((err) => {
        notifyMessageError('Visualizar', err);
        return false;
      });
  };

  const getStatus = async () => {
    await api.get(`${baseUrl}/getStatus`)
      .then((response) => {
        setStatus(response.data.data);
        return true;
      })
      .catch((err) => {
        notifyMessageError('Visualizar', err);
        return false;
      });
  };

  const getLastOrders = async () => {
    await api.get(`${baseUrl}/getLastOrders`)
      .then((response) => {
        setLastOrders(response.data.data);
        return true;
      })
      .catch((err) => {
        notifyMessageError('Visualizar', err);
        return false;
      });
  };

  const getExpiredOrders = async () => {
    await api.get(`${baseUrl}/getOrdersExpired`)
      .then((response) => {
        setExpiredOrders(response.data.data);
        return true;
      })
      .catch((err) => {
        notifyMessageError('Visualizar', err);
        return false;
      });
  };

  const getLastProducts = async () => {
    await api.get(`${baseUrl}/getLastProducts`)
      .then((response) => {
        setLastProducts(response.data.data);
        return true;
      })
      .catch((err) => {
        notifyMessageError('Visualizar', err);
        return false;
      });
  };

  return (
    <DashboardContext.Provider value={{
      closeNotifyMessage,
      notifyMessageSave,
      notifyMessageError,
      getStatusPie,
      getStatus,
      expiredOrders,
      getLastOrders,
      getLastProducts,
      getExpiredOrders,
      lastOrders,
      lastProducts,
      statusPie,
      status,
      setLoading
    }}
    >
      { children }
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
