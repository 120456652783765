import { Helmet } from 'react-helmet';
import {
  Box, Container, Grid, Typography
} from '@mui/material';
import ProductListResults from 'src/components/product/ProductListResults';
import ProductListToolbar from 'src/components/product/ProductListToolbar';
import { ProductContext } from 'src/context/ProductContext';
import { useContext, useEffect } from 'react';

const ProductList = () => {
  const {
    products,
    getProducts,
    deleteProduct,
    searchProducts,
    paginateProduct,
  } = useContext(ProductContext);
  useEffect(() => {
    getProducts();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`Produtos | ${process.env.REACT_APP_NAME}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <ProductListToolbar search={searchProducts} />
          <Box sx={{ pt: 3 }}>
            {products.data && products.data.length > 0
              ? <ProductListResults products={products} exclude={deleteProduct} paginate={paginateProduct} />
              : (
                <>
                  <Grid container alignContent="center" justifyContent="center" alignItems="center" lg={12}>
                    <Typography variant="h3">
                      Nenhum registro encontrado
                    </Typography>
                  </Grid>
                </>
              )}
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default ProductList;
