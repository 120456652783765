import {
  React,
  useState,
  createContext
} from 'react';

export const SiteContext = createContext();

const SiteProvider = (prop) => {
  const { children } = prop;
  const [loading, setLoading] = useState(false);
  const [notifyMessage, setNotifyMessage] = useState({
    text: null,
    type: 'success',
    open: false
  });

  const closeNotifyMessage = () => {
    setNotifyMessage({
      ...notifyMessage,
      open: false
    });
  };

  const notifyMessageSave = (action) => {
    setNotifyMessage({
      text: `Registro ${action} com Sucesso !`,
      type: 'success',
      open: true
    });
  };

  const notifyMessageError = (action, err) => {
    setNotifyMessage({
      text: `Erro ao ${action} =( ${err}`,
      type: 'error',
      open: true
    });
  };

  return (
    <SiteContext.Provider
      value={{
        loading,
        notifyMessage,
        setLoading,
        setNotifyMessage,
        closeNotifyMessage,
        notifyMessageError,
        notifyMessageSave
      }}
    >
      { children }
    </SiteContext.Provider>

  );
};

export default SiteProvider;
