import {
  React,
  useState,
  createContext,
  useContext
} from 'react';
import api from 'src/services/api';
import { SiteContext } from './SiteContext';

export const ProductContext = createContext();

const ProductProvider = (prop) => {
  const baseUrl = 'user/product';
  const { children } = prop;
  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [productsAll, setProductsAll] = useState([]);
  const [urlPaginate, setUrlPaginate] = useState();
  const [isClient, setIsClient] = useState(null);
  const {
    notifyMessage,
    setNotifyMessage,
    loading,
    setLoading
  } = useContext(SiteContext);
  const [search, setSearch] = useState();

  const closeNotifyMessage = () => {
    setNotifyMessage({
      ...notifyMessage,
      open: false
    });
  };

  const notifyMessageSave = (action) => {
    setNotifyMessage({
      text: `Registro ${action} com Sucesso !`,
      type: 'success',
      open: true
    });
  };

  const notifyMessageError = (action, err) => {
    setNotifyMessage({
      text: `Erro ao ${action} =( ${err}`,
      type: 'error',
      open: true
    });
  };

  const getProducts = async () => {
    setLoading(true);
    setIsClient(null);
    await api.get(baseUrl)
      .then((response) => {
        setProducts(response.data);
        setUrlPaginate(response.data.meta.path);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar', err);
        return false;
      });
  };

  const getAllProducts = async () => {
    setLoading(true);
    await api.get(`${baseUrl}/nopaginate`)
      .then((response) => {
        setProductsAll(response.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar todos', err);
        return false;
      });
  };

  const getProduct = async (id) => {
    setLoading(true);
    setIsClient(null);
    await api.get(`${baseUrl}/${id}`)
      .then((response) => {
        setProduct(response.data.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar', err);
        return false;
      });
  };

  const saveProduct = async (data) => {
    setLoading(true);
    const serverResponse = await api.post(baseUrl, data)
      .then((response) => {
        setProduct(response.data.data);
        setLoading(false);
        notifyMessageSave('Salvo');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Salvar', err);
        return false;
      });
    return serverResponse;
  };

  const updateProduct = async (id, data) => {
    setLoading(true);
    await api.post(`${baseUrl}/${id}`, data)
      .then((response) => {
        setProduct(response.data.data);
        setLoading(false);
        notifyMessageSave('Atualizado');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Atualizar', err);
        return false;
      });
  };

  const searchProducts = async (param) => {
    setLoading(true);
    setIsClient(null);
    if (!param) {
      await getProducts();
    } else {
      await api.post(`${baseUrl}/search`, { param })
        .then((response) => {
          setProducts(response.data);
          setUrlPaginate(response.data.meta.path);
          setSearch(param);
          setLoading(false);
        })
        .catch((err) => {
          setProducts([]);
          setLoading(false);
          notifyMessageError('Pesquisar', err);
        });
    }
  };

  const paginateProduct = async (number) => {
    setLoading(true);
    setIsClient(null);
    if (search) {
      await api.post(`${urlPaginate}?page=${number}`, { search })
        .then((response) => {
          setProducts(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setProducts([]);
          setLoading(false);
          notifyMessageError('Paginar', err);
        });
    } else {
      await api.get(`${urlPaginate}?page=${number}`)
        .then((response) => {
          setProducts(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setProducts([]);
          setLoading(false);
          notifyMessageError('Paginar', err);
        });
    }
  };

  const getProductsByClient = async (id) => {
    setLoading(true);
    setIsClient(id);
    await api.get(`${baseUrl}/client/${id}`)
      .then((response) => {
        setProducts(response.data);
        setUrlPaginate(response.data.meta.path);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar por cliente', err);
        return false;
      });
  };

  const getAllProductsByClient = async (id) => {
    setLoading(true);
    setIsClient(id);
    await api.get(`${baseUrl}/client/${id}/all`)
      .then((response) => {
        setProductsAll(response.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar por cliente', err);
        return false;
      });
  };

  const searchProductsByClient = async (param) => {
    setLoading(true);
    if (!param) {
      await getProductsByClient(isClient);
    } else {
      const data = {
        param,
        client_id: isClient
      };
      await api.post(`${baseUrl}/search/client`, data)
        .then((response) => {
          setProducts(response.data);
          setUrlPaginate(response.data.meta.path);
          setSearch(param);
          setLoading(false);
        })
        .catch((err) => {
          setProducts([]);
          notifyMessageError('Pesquisar por cliente', err);
          console.log(err);
        });
    }
  };

  const paginateProductByClient = async (number) => {
    setLoading(true);
    if (search) {
      await api.post(`${urlPaginate}?page=${number}`, { search })
        .then((response) => {
          setProducts(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setProducts([]);
          setLoading(false);
          notifyMessageError('Paginar por cliente', err);
        });
    } else {
      await api.get(`${urlPaginate}?page=${number}`)
        .then((response) => {
          setProducts(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setProducts([]);
          setLoading(false);
          notifyMessageError('Paginar por cliente', err);
        });
    }
  };

  const deleteProduct = async (id) => {
    setLoading(true);
    const response = await api.delete(`${baseUrl}/${id}`)
      .then(() => {
        setLoading(false);
        notifyMessageSave('excluido');
        if (!isClient) {
          getProducts();
        } else {
          getProductsByClient(isClient);
        }
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Excluir', err);
        return false;
      });
    return response;
  };

  return (
    <ProductContext.Provider
      value={{
        product,
        products,
        productsAll,
        loading,
        urlPaginate,
        getProducts,
        saveProduct,
        updateProduct,
        setProduct,
        getAllProducts,
        setProductsAll,
        deleteProduct,
        getProductsByClient,
        getAllProductsByClient,
        paginateProductByClient,
        paginateProduct,
        getProduct,
        setNotifyMessage,
        searchProducts,
        searchProductsByClient,
        closeNotifyMessage,
        notifyMessageError,
        notifyMessageSave
      }}
    >
      { children }
    </ProductContext.Provider>

  );
};

export default ProductProvider;
