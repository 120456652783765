import { Helmet } from 'react-helmet';
import {
  AppBar,
  Box,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import ClientProfileDetails from 'src/components/client/ClientProfileDetails';
import ProductListResults from 'src/components/client/ProductListResults';
import ProductListToolbar from 'src/components/client/ProductListToolbar';
import { useContext, useEffect, useState } from 'react';
import { ClientContext } from 'src/context/ClientContext';
import { useParams } from 'react-router-dom';
import { DocumentTypeContext } from 'src/context/DocumentTypeContext';
import PropTypes from 'prop-types';
import { ProductContext } from 'src/context/ProductContext';
import ContactListResults from 'src/components/client/ContactListResults';
import { OrderContext } from 'src/context/OrderContext';
import OrderClientListToolbar from 'src/components/client/OrderClientListToolbar';
import OrderClientListResults from 'src/components/client/OrderClientListResults';

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const tab = {
  color: '#FFF'
};

const tabSelected = {
  color: '#FFF',
  borderBottom: '5px solid red'
};

const ClientForm = () => {
  const { id } = useParams();
  const {
    client,
    setClient,
    getClient,
    saveClient,
    updateClient,
    restoreClient,
    getClientContact,
    saveClientContact,
    updateClientContact,
    deleteClientContact,
  } = useContext(ClientContext);
  const {
    products,
    searchProductsByClient,
    deleteProduct,
    paginateProductByClient,
    getProductsByClient
  } = useContext(ProductContext);
  const { documentTypesAll, getAllDocumentTypes } = useContext(DocumentTypeContext);
  const {
    order, orders, status, getStatus, searchOrdersByClient, getOrdersByClient, paginateOrder
  } = useContext(OrderContext);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(async () => {
    await getAllDocumentTypes();
    if (id) {
      await getClient(id);
      await getProductsByClient(id);
      await getOrdersByClient(id);
      await getStatus();
    } else {
      setClient(null);
    }
  }, []);

  const reloadData = async () => {
    if (id) {
      await getClient(id);
      await getProductsByClient(id);
    }
  };

  useEffect(() => {
    if (id) {
      if (client && documentTypesAll.data && documentTypesAll.data.length > 0) {
        setLoading(false);
      }
    }

    if (!id) {
      if (documentTypesAll.data && documentTypesAll.data.length > 0) {
        setLoading(false);
      }
    }
  }, [documentTypesAll, client]);

  return (
    <>
      <Helmet>
        <title>
          {`Client | ${process.env.REACT_APP_NAME}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          {loading === false
            ? (
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                      <Tab style={value === 0 ? tabSelected : tab} label="Dados" {...a11yProps(0)} />
                      <Tab disabled={!client} style={value === 1 ? tabSelected : tab} label="Contatos" {...a11yProps(1)} />
                      <Tab disabled={!client} style={value === 2 ? tabSelected : tab} label="Produtos" {...a11yProps(2)} />
                      <Tab disabled={!client} style={value === 3 ? tabSelected : tab} label="Ordens" {...a11yProps(3)} />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={value} index={0} style={{ backgroundColor: '#fff' }}>
                    <ClientProfileDetails
                      client={client}
                      documenttypes={documentTypesAll}
                      restore={restoreClient}
                      reload={reloadData}
                      saveform={saveClient}
                      updateform={updateClient}
                    />
                  </TabPanel>
                  { client
                    ? (
                      <>
                        <TabPanel value={value} index={1} style={{ backgroundColor: '#fff' }}>
                          <ContactListResults
                            client={client}
                            getContact={getClientContact}
                            contacts={client.contacts}
                            exclude={deleteClientContact}
                            saveContact={saveClientContact}
                            updateContact={updateClientContact}
                          />
                        </TabPanel>
                        <TabPanel value={value} index={2} style={{ backgroundColor: '#fff' }}>
                          <ProductListToolbar client={client} search={searchProductsByClient} />
                          <ProductListResults products={products} exclude={deleteProduct} paginate={paginateProductByClient} />
                        </TabPanel>
                        <TabPanel value={value} index={3} style={{ backgroundColor: '#fff' }}>
                          <OrderClientListToolbar id={client.id} status={status} order={order} search={searchOrdersByClient} />
                          <OrderClientListResults orders={orders} paginate={paginateOrder} />
                        </TabPanel>
                      </>
                    )
                    : <></> }
                </Grid>
              </Grid>
            )
            : <></> }
        </Container>
      </Box>
    </>
  );
};
export default ClientForm;
