import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function CancelOrderModal({ modal }) {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    reason_canceled: '',
    error: false
  });
  const handleChange = (event) => {
    setValues({
      [event.target.name]: event.target.value,
      error: false
    });
  };

  useEffect(() => {
    setOpen(modal.open);
  }, [modal]);

  const handleConfirm = () => {
    if (values.reason_canceled === '') {
      setValues({
        ...values,
        error: true
      });
    } else {
      modal.confirm(values);
    }
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
      modal.cancel();
    }
  };

  return (
    <>
      <BootstrapDialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Cancelar ordem de serviço
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            <Grid
              spacing={3}
              container
            >
              <Grid
                item
                xs={12}
              >
                <TextField
                  error={values.error}
                  fullWidth
                  multiline
                  required
                  rows={4}
                  label="Motivo do Cancelamento"
                  name="reason_canceled"
                  onChange={handleChange}
                  value={values.observation}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={handleClose} color="default">
            Cancelar
          </Button>
          <Button variant="contained" onClick={handleConfirm} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

CancelOrderModal.propTypes = {
  modal: PropTypes.isRequired
};
