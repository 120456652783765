import { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  Edit as EditIcon,
  Trash2 as TrashIcon
} from 'react-feather';
import DeleteModal from 'src/components/modal/DeleteModal';
import ClientContactModal from '../modal/ClientContactModal';

const ContactListResults = ({
  client,
  contacts,
  exclude,
  getContact,
  saveContact,
  updateContact,
  ...rest
}) => {
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: null,
    text: 'Tem certeza que deseja excluir este Contato ?',
  });

  const [contactModal, setConctactModal] = useState({
    open: false,
    contact: null,
    title: 'Cadastrar contato',
  });

  const confirmModalContact = async (data) => {
    const saveData = {
      ...data,
      client_id: client.id
    };
    if (data.id) {
      await updateContact(data.id, saveData);
    } else {
      await saveContact(saveData);
    }

    setConctactModal({
      ...contactModal,
      open: false
    });
    console.log('passou');
  };

  const editConctact = async (id) => {
    const contact = await getContact(id);
    if (contact) {
      setConctactModal(
        {
          ...contactModal,
          contact,
          title: 'Editar contato',
          open: true,
          cancel: () => { },
          confirm: (data) => { confirmModalContact(data); }
        }
      );
    }
  };

  const addContact = () => {
    setConctactModal(
      {
        ...contactModal,
        contact: null,
        title: 'Cadastrar contato',
        open: true,
        cancel: () => { },
        confirm: (data) => { confirmModalContact(data); }
      }
    );
  };

  const excludeProduct = async (id) => {
    const confirm = await exclude(id);
    if (confirm) {
      setDeleteModal({
        ...deleteModal,
        open: false
      });
    }
  };

  const deleteProduct = (id) => {
    setDeleteModal({
      ...deleteModal,
      id,
      open: true,
      confirm: excludeProduct,
      cancel: () => { }
    });
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>

        <Button
          color="primary"
          variant="contained"
          onClick={addContact}
          style={{ margin: 10, float: 'right' }}
        >
          Adicionar Contato
        </Button>

        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Nome
                </TableCell>
                <TableCell>
                  Contato
                </TableCell>
                <TableCell>
                  Whatsapp
                </TableCell>
                <TableCell>
                  Editar
                </TableCell>
                <TableCell>
                  Excluir
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contacts.map((contact) => (
                <TableRow
                  hover
                  key={contact.id}
                >
                  <TableCell>
                    {contact.name}
                  </TableCell>
                  <TableCell>
                    {contact.contact}
                  </TableCell>
                  <TableCell>
                    {contact.whatsapp ? 'Sim' : 'Não'}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => { editConctact(contact.id); }}><EditIcon /></Button>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => { deleteProduct(contact.id); }}><TrashIcon /></Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <DeleteModal modal={deleteModal} />
      <ClientContactModal modal={contactModal} />
    </Card>
  );
};

ContactListResults.propTypes = {
  client: PropTypes.object.isRequired,
  contacts: PropTypes.array.isRequired,
  exclude: PropTypes.func.isRequired,
  clientContact: PropTypes.array.isRequired,
  getContact: PropTypes.func.isRequired,
  saveContact: PropTypes.func.isRequired,
  updateContact: PropTypes.func.isRequired
};

export default ContactListResults;
