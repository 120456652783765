import {
  React,
  useState,
  createContext,
  useContext
} from 'react';
import api from 'src/services/api';
import { SiteContext } from './SiteContext';
import { OrderContext } from './OrderContext';

export const OrderServiceContext = createContext();

const OrderServiceProvider = (prop) => {
  const baseUrl = 'user/orderService';
  const { children } = prop;
  const [orderService, setOrderService] = useState(null);
  const [orderServices, setOrderServices] = useState([]);
  const [status, setStatus] = useState([]);
  const [urlPaginate, setUrlPaginate] = useState();
  const {
    notifyMessage,
    setNotifyMessage,
    loading,
    setLoading
  } = useContext(SiteContext);
  const { getOrder } = useContext(OrderContext);
  const [search, setSearch] = useState();

  const closeNotifyMessage = () => {
    setNotifyMessage({
      ...notifyMessage,
      open: false
    });
  };

  const notifyMessageSave = (action) => {
    setNotifyMessage({
      text: `Registro ${action} com Sucesso !`,
      type: 'success',
      open: true
    });
  };

  const notifyMessageError = (action, err) => {
    setNotifyMessage({
      text: `Erro ao ${action} =( ${err}`,
      type: 'error',
      open: true
    });
  };

  const getOrders = async () => {
    setLoading(true);
    await api.get(baseUrl)
      .then((response) => {
        setOrderServices(response.data);
        setUrlPaginate(response.data.meta.path);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar', err);
        return false;
      });
  };

  const getStatus = async () => {
    setLoading(true);
    await api.get(`${baseUrl}/status`)
      .then((response) => {
        setStatus(response.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar status', err);
        return false;
      });
  };

  const getOrderService = async (id) => {
    setLoading(true);
    const data = await api.get(`${baseUrl}/${id}`)
      .then((response) => {
        setOrderService(response.data.data);
        setLoading(false);
        return response.data.data;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar', err);
      });
    return data;
  };

  const searchOrders = async (param) => {
    setLoading(true);
    if (!param) {
      await getOrders();
    } else {
      await api.post(`${baseUrl}/search`, param)
        .then((response) => {
          setOrderServices(response.data);
          setUrlPaginate(response.data.meta.path);
          setSearch(param);
          setLoading(false);
        })
        .catch((err) => {
          setOrderServices([]);
          setLoading(false);
          notifyMessageError('Pesquisar', err);
        });
    }
  };

  const paginateOrder = async (number) => {
    setLoading(true);
    if (search) {
      await api.post(`${urlPaginate}?page=${number}`, search)
        .then((response) => {
          setOrderServices(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setOrderServices([]);
          setLoading(false);
          notifyMessageError('Paginar', err);
        });
    } else {
      await api.get(`${urlPaginate}?page=${number}`)
        .then((response) => {
          setOrderServices(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setOrderServices([]);
          setLoading(false);
          notifyMessageError('Paginar', err);
        });
    }
  };

  const saveOrderService = async (data) => {
    setLoading(true);
    const serverResponse = await api.post(baseUrl, data)
      .then((response) => {
        setOrderService(response.data.data);
        setLoading(false);
        notifyMessageSave('Salvo');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Salvar', err);
        return false;
      });
    return serverResponse;
  };

  const updateOrderService = async (id, data) => {
    setLoading(true);
    await api.post(`${baseUrl}/${id}`, data)
      .then((response) => {
        getOrder(data.order_id);
        setOrderService(response.data.data);
        setLoading(false);
        notifyMessageSave('Atualizado');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Atualizar', err);
        return false;
      });
  };

  const deleteOrder = async (id) => {
    setLoading(true);
    const response = await api.delete(`${baseUrl}/${id}`)
      .then(() => {
        setLoading(false);
        notifyMessageSave('excluido');
        getOrders();
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Excluir', err);
        return false;
      });
    return response;
  };

  return (
    <OrderServiceContext.Provider
      value={{
        orderService,
        orderServices,
        loading,
        status,
        urlPaginate,
        getOrders,
        setOrderService,
        getStatus,
        deleteOrder,
        paginateOrder,
        saveOrderService,
        updateOrderService,
        getOrderService,
        setNotifyMessage,
        searchOrders,
        closeNotifyMessage,
        notifyMessageError,
        notifyMessageSave
      }}
    >
      { children }
    </OrderServiceContext.Provider>

  );
};

export default OrderServiceProvider;
