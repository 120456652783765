import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon
} from '@mui/material';
import { Search as SearchIcon } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ProductListToolbar = (props) => {
  const navigate = useNavigate();
  const { search, client } = props;

  const handleChange = async (event) => {
    console.log(event.target.value);
    await search(event.target.value);
  };

  const buttonNew = () => {
    navigate(`/app/product/client/${client.id}`, { replace: true });
  };

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={buttonNew}
        >
          Adicionar Produto
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                fullWidth
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Pesquisar"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

ProductListToolbar.propTypes = {
  search: PropTypes.isRequired,
  client: PropTypes.object.isRequired
};

export default ProductListToolbar;
