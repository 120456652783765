import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab//TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import BuildIcon from '@material-ui/icons/Build';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Avatar, Grid } from '@mui/material';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function OrderServiceTimeLineHistory({ histories }) {
  const classes = useStyles();

  return (
    <Timeline align="alternate">
      {
          histories.map((history) => (
            <TimelineItem>
              <TimelineOppositeContent>
                <Typography variant="body2" color="textSecondary">
                  {history.date_service}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot>
                  <BuildIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Grid container xs={12}>
                    <Grid xs={3}>
                      <Avatar alt={history.technician.name} src={history.technician.image} />
                    </Grid>
                    <Grid xs={9}>
                      <Typography variant="h6" component="h1">
                        {history.technician.name}
                      </Typography>
                    </Grid>
                    <Grid xs={12} style={{ margin: 10 }}>
                      <Typography>{history.description}</Typography>
                    </Grid>
                    <Grid xs={12}>
                      <Typography>
                        <Alert severity={history.status === 'F' ? 'success' : 'warning'}>{history.status_description}</Alert>
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          ))
      }
    </Timeline>
  );
}

OrderServiceTimeLineHistory.propTypes = {
  histories: PropTypes.array
};
