import { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import getInitials from 'src/utils/getInitials';
import {
  Eye as EyeIcon
} from 'react-feather';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const HistoryListResults = ({
  orders,
  exclude,
  paginate,
  ...rest
}) => {
  const navigate = useNavigate();
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = (event) => {
    let newSelectedProductIds;

    if (event.target.checked) {
      newSelectedProductIds = orders.map((order) => order.id);
    } else {
      newSelectedProductIds = [];
    }

    setSelectedProductIds(newSelectedProductIds);
  };

  const formatDate = (value) => {
    if (value) {
      console.log(value);
      const date = new Date(value);
      return moment(date).format('DD/MM/YYYY HH:mm');
    }
    return value;
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedProductIds.indexOf(id);
    let newSelectedProductIds = [];

    if (selectedIndex === -1) {
      newSelectedProductIds = newSelectedProductIds.concat(selectedProductIds, id);
    } else if (selectedIndex === 0) {
      newSelectedProductIds = newSelectedProductIds.concat(selectedProductIds.slice(1));
    } else if (selectedIndex === selectedProductIds.length - 1) {
      newSelectedProductIds = newSelectedProductIds.concat(selectedProductIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedProductIds = newSelectedProductIds.concat(
        selectedProductIds.slice(0, selectedIndex),
        selectedProductIds.slice(selectedIndex + 1)
      );
    }

    setSelectedProductIds(newSelectedProductIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    paginate((newPage + 1));
  };

  const buttonEdit = (id) => {
    navigate(`/app/order/${id}`, { replace: true });
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedProductIds.length === orders.length}
                    color="primary"
                    indeterminate={
                      selectedProductIds.length > 0
                      && selectedProductIds.length < orders.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  Cliente
                </TableCell>
                <TableCell>
                  Entrada
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
                <TableCell>
                  Finalização
                </TableCell>
                <TableCell>
                  Cancelado
                </TableCell>
                <TableCell>
                  Ver
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.data.slice(0, limit).map((order) => (
                <TableRow
                  hover
                  key={order.id}
                  selected={selectedProductIds.indexOf(order.id) !== -1}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedProductIds.indexOf(order.id) !== -1}
                      onChange={(event) => handleSelectOne(event, order.id)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Avatar
                        src={order.client.image}
                        sx={{ mr: 2 }}
                      >
                        {getInitials(order.client.name)}
                      </Avatar>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {order.client.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {formatDate(order.entry_date)}
                  </TableCell>
                  <TableCell>
                    {order.status_details}
                  </TableCell>
                  <TableCell>
                    {formatDate(order.finished_date)}
                  </TableCell>
                  <TableCell>
                    {formatDate(order.canceled_date)}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => { buttonEdit(order.id); }}><EyeIcon /></Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={orders.meta.total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[]}
      />
    </Card>
  );
};

HistoryListResults.propTypes = {
  orders: PropTypes.array.isRequired,
  exclude: PropTypes.array.isRequired,
  paginate: PropTypes.array.isRequired
};

export default HistoryListResults;
