import {
  NavLink as RouterLink,
  matchPath,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ListItem } from '@mui/material';

const NavItem = ({
  path,
  href,
  icon: Icon,
  title,
  ...rest
}) => {
  const location = useLocation();

  let active = href ? !!matchPath({
    path: href,
    end: false
  }, location.pathname) : false;

  if (!active) {
    active = href ? !!matchPath({
      path: href.slice(0, -1),
      end: false
    }, location.pathname) : false;
  }

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0
      }}
      {...rest}
    >
      <Button
        fullWidth
        component={RouterLink}
        to={href}
        style={{
          justifyContent: 'left'
        }}
      >
        {Icon && (
          <Icon size="20" />
        )}
        <span style={{ marginLeft: 10 }}>
          {title}
        </span>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  path: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
