/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Clipboard as OrderIcon
} from 'react-feather';
import getInitials from 'src/utils/getInitials';

const ProductProfileDetails = (props) => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    image: '',
    name: '',
    brand: '',
    client_id: '',
    client_name: '',
    internal_code: '',
    model: '',
    observation: '',
  });

  const [image, setImage] = useState(null);

  const {
    clientId,
    product,
    saveform,
    updateform,
    clients,
  } = props;

  useEffect(() => {
    if (product) {
      setValues({
        image: product.image,
        name: product.name,
        brand: product.brand,
        internal_code: product.internal_code,
        client_id: product.client ? product.client.id : 0,
        model: product.model,
        observation: product.observation,
      });
    }
  }, [product]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  useEffect(() => {
    if (clientId) {
      setValues({
        ...values,
        client_id: clientId
      });
    }
  }, []);

  const handleChangeFile = (event) => {
    setImage(event.target.files[0]);
    setValues({
      ...values,
      image: URL.createObjectURL(event.target.files[0])
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    if (image) {
      formData.append('image', image);
    } else {
      formData.delete('image');
    }

    if (product) {
      await updateform(product.id, formData);
      navigate(`/app/client/${values.client_id}`, { replace: true });
    } else {
      const save = await saveform(formData);
      if (save) {
        navigate(`/app/client/${values.client_id}`, { replace: true });
      }
    }
  };

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit}
      {...props}
    >
      {values.client_id === 0
        ? <Alert severity="warning"> O dono deste produto foi excluido, caso o produto tenha sido devolvido exclua ele se não adicione ele a outro cliente </Alert>
        : <></>}
      <Card>
        <CardHeader
          subheader="Dados do Produto"
          title="Dados"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <Avatar
                sx={{ width: 100, height: 100 }}
                lg={{ width: 200, height: 200 }}
                src={values.image}
              >
                {getInitials(values.name)}
              </Avatar>
              <Typography>Imagem Atual</Typography>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                name="image"
                onChange={handleChangeFile}
                type="file"
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Cliente"
                name="client_id"
                onChange={handleChange}
                required
                disabled={clientId}
                select
                SelectProps={{ native: true }}
                value={values.client_id}
                variant="outlined"
              >
                <option />
                {clients.data.map((option) => (
                  <option
                    key={option.id}
                    value={option.id}
                  >
                    {option.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Identificador (Numero de Serie, Codigo de Barras, Etc ...)"
                name="internal_code"
                onChange={handleChange}
                value={values.internal_code}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nome"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Marca"
                name="brand"
                onChange={handleChange}
                required
                value={values.brand}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Modelo"
                name="model"
                onChange={handleChange}
                required
                value={values.model}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Observação"
                name="observation"
                onChange={handleChange}
                multiline
                rows={4}
                required
                value={values.observation}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          {product && values.client_id !== 0 ? (
            <>
              <Button
                color="primary"
                variant="contained"
                style={{ margin: 10 }}
                onClick={() => { navigate(`/app/order/product/${product.id}`, { replace: true }); }}
              >
                <OrderIcon />
                Abrir Ordem
              </Button>
            </>
          )
            : <></>}
          <Button
            color="primary"
            variant="contained"
            style={{ margin: 10 }}
            type="submit"
          >
            Salvar
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ProductProfileDetails.propTypes = {
  clientId: PropTypes.number,
  clients: PropTypes.object,
  client: PropTypes.object,
  product: PropTypes.object.isRequired,
  updateform: PropTypes.func.isRequired,
  saveform: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  getClient: PropTypes.func.isRequired,
};

export default ProductProfileDetails;
