import {
  React,
  useState,
  createContext,
  useContext
} from 'react';
import api from 'src/services/api';
import { SiteContext } from './SiteContext';

export const TechnicianContext = createContext();

const TechnicianProvider = (prop) => {
  const baseUrl = 'user/technician';
  const { children } = prop;
  const [technician, setTechnician] = useState(null);
  const [technicians, setTechnicians] = useState([]);
  const [techniciansAll, setTechniciansAll] = useState([]);
  const [urlPaginate, setUrlPaginate] = useState();
  const {
    notifyMessage,
    setNotifyMessage,
    loading,
    setLoading
  } = useContext(SiteContext);
  const [search, setSearch] = useState();

  const closeNotifyMessage = () => {
    setNotifyMessage({
      ...notifyMessage,
      open: false
    });
  };

  const notifyMessageSave = (action) => {
    setNotifyMessage({
      text: `Registro ${action} com Sucesso !`,
      type: 'success',
      open: true
    });
  };

  const notifyMessageError = (action, err) => {
    setNotifyMessage({
      text: `Erro ao ${action} =( ${err}`,
      type: 'error',
      open: true
    });
  };

  const getTechnicians = async () => {
    setLoading(true);
    await api.get(baseUrl)
      .then((response) => {
        setTechnicians(response.data);
        setUrlPaginate(response.data.meta.path);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar', err);
        return false;
      });
  };

  const getAllTechnicians = async () => {
    setLoading(true);
    await api.get(`${baseUrl}/nopaginate`)
      .then((response) => {
        setTechniciansAll(response.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar todos', err);
        return false;
      });
  };

  const getTechnician = async (id) => {
    setLoading(true);
    await api.get(`${baseUrl}/${id}`)
      .then((response) => {
        setTechnician(response.data.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar', err);
        return false;
      });
  };

  const saveTechnician = async (data) => {
    setLoading(true);
    const serverResponse = await api.post(baseUrl, data)
      .then((response) => {
        setTechnician(response.data.data);
        setLoading(false);
        notifyMessageSave('Salvo');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Salvar', err);
        return false;
      });
    return serverResponse;
  };

  const updateTechnician = async (id, data) => {
    setLoading(true);
    await api.post(`${baseUrl}/${id}`, data)
      .then((response) => {
        setTechnician(response.data.data);
        setLoading(false);
        notifyMessageSave('Atualizado');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Atualizar', err);
        return false;
      });
  };

  const searchTechnicians = async (param) => {
    setLoading(true);
    if (!param) {
      await getTechnicians();
    } else {
      await api.post(`${baseUrl}/search`, { param })
        .then((response) => {
          setTechnicians(response.data);
          setUrlPaginate(response.data.meta.path);
          setSearch(param);
          setLoading(false);
        })
        .catch((err) => {
          setTechnicians([]);
          notifyMessageError('Pesquisar', err);
          console.log(err);
        });
    }
  };

  const paginateTechnician = async (number) => {
    setLoading(true);
    if (search) {
      await api.post(`${urlPaginate}?page=${number}`, { search })
        .then((response) => {
          setTechnicians(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setTechnicians([]);
          setLoading(false);
          console.log(err);
        });
    } else {
      await api.get(`${urlPaginate}?page=${number}`)
        .then((response) => {
          setTechnicians(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setTechnicians([]);
          setLoading(false);
          notifyMessageError('Paginar', err);
        });
    }
  };

  const deleteTechnician = async (id) => {
    setLoading(true);
    const response = await api.delete(`${baseUrl}/${id}`)
      .then(() => {
        setLoading(false);
        notifyMessageSave('excluido');
        getTechnicians();
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Excluir', err);
        return false;
      });
    return response;
  };

  return (
    <TechnicianContext.Provider
      value={{
        technician,
        technicians,
        techniciansAll,
        loading,
        urlPaginate,
        getTechnicians,
        saveTechnician,
        updateTechnician,
        setTechnician,
        deleteTechnician,
        paginateTechnician,
        getTechnician,
        getAllTechnicians,
        setNotifyMessage,
        searchTechnicians,
        closeNotifyMessage,
        notifyMessageError,
        notifyMessageSave
      }}
    >
      { children }
    </TechnicianContext.Provider>

  );
};

export default TechnicianProvider;
