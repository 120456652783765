import moment from 'moment';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  Eye as EyeIcon,
} from 'react-feather';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const LatestOrders = (props) => {
  const navigate = useNavigate();
  const { orders } = props;

  const viewOrders = () => {
    navigate('/app/orders', { replace: true });
  };

  const viewOrder = (id) => {
    navigate(`/app/order/${id}`, { replace: true });
  };
  return (
    <Card {...props}>
      <CardHeader title="Útimas ordens" />
      <Divider />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Numero
                </TableCell>
                <TableCell>
                  Produto
                </TableCell>
                <TableCell>
                  Cliente
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
                <TableCell>
                  Data Entrada
                </TableCell>
                <TableCell>
                  Visualizar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => (
                <TableRow
                  hover
                  key={order.id}
                >
                  <TableCell>
                    {order.id}
                  </TableCell>
                  <TableCell>
                    {order.product.name}
                  </TableCell>
                  <TableCell>
                    {order.client.name}
                  </TableCell>
                  <TableCell>
                    <Chip
                      color="primary"
                      label={order.status_details}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    {moment(order.entry_date).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => { viewOrder(order.id); }}>
                      <EyeIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button
          color="primary"
          onClick={viewOrders}
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          Ver todas
        </Button>
      </Box>
    </Card>
  );
};

LatestOrders.propTypes = {
  orders: PropTypes.array.isRequired,
};

export default LatestOrders;
