import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  TablePagination,
  Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/styles';
import {
  Edit as EditIcon,
  Users as UsersIcon,
  ShoppingBag as ShoppingBagIcon,
  Cpu as CpuIcon,
  Activity as ActivityIcon,
  Tool as ToolIcon,
  Calendar as CalendarIcon,
} from 'react-feather';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
});

const HistoryListResults = ({
  orders,
  paginate,
}) => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const classes = useStyles();

  const formatDate = (value) => {
    if (value) {
      console.log(value);
      const date = new Date(value);
      return moment(date).format('DD/MM/YYYY HH:mm');
    }
    return value;
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    paginate((newPage + 1));
  };

  const buttonEdit = (id) => {
    navigate(`/app/order/${id}`, { replace: true });
  };

  return (
    <>
      <Box sx={{ minWidth: 1050 }}>
        <Grid
          container
          spacing={3}
        >
          {orders.data.slice(0, limit).map((order) => (
            <Grid item xs={12} md={3} lg={3}>
              <Card className={classes.root}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt={order.product.name}
                    sx={1}
                    image={order.product.image ? order.product.image : '/image/no-image.png'}
                    title={order.product.name}
                  />
                  <CardContent>
                    <Typography title="Numero" gutterBottom variant="h6" component="h2">
                      <CpuIcon title="Número da Ordem" style={{ marginRight: 15 }} />
                      {order.id}
                    </Typography>
                    <Typography title="Produto" gutterBottom variant="h6" component="h2">
                      <ShoppingBagIcon style={{ marginRight: 15 }} />
                      {order.product.name}
                    </Typography>
                    <Typography title="Cliente" gutterBottom variant="h6" component="h2">
                      <UsersIcon style={{ marginRight: 15 }} />
                      {order.client.name}
                      {' '}
                      {order.client.deleted
                    && (
                    <Chip
                      size="small"
                      label="Cliente Excluido"
                      color="secondary"
                    />
                    )}
                    </Typography>
                    <Typography title="Status" gutterBottom variant="h6" component="h2">
                      <ActivityIcon style={{ marginRight: 15 }} />
                      {order.status_details}
                    </Typography>
                    <Typography title="Tecnico" gutterBottom variant="h6" component="h2">
                      <ToolIcon style={{ marginRight: 15 }} />
                      {order.technician ? order.technician.name : 'Sem'}
                    </Typography>
                    <Typography title="Data Entrada" gutterBottom variant="h6" component="h2">
                      <CalendarIcon color="gray" style={{ marginRight: 15 }} />
                      {order.entry_date ? `Entrada - ${formatDate(order.entry_date)}` : 'Sem'}
                    </Typography>
                    <Typography title="Data Início" gutterBottom variant="h6" component="h2">
                      <CalendarIcon color="orange" style={{ marginRight: 15 }} />
                      {order.execute_date ? `Início - ${formatDate(order.execute_date)}` : 'Sem'}
                    </Typography>
                    <Typography title="Data Pronto" gutterBottom variant="h6" component="h2">
                      <CalendarIcon color="blue" style={{ marginRight: 15 }} />
                      {order.ready_date ? `Pronto - ${formatDate(order.ready_date)}` : 'Sem'}
                    </Typography>
                    {order.finished_date
                    && (
                    <Typography title="Data Finalizada" gutterBottom variant="h6" component="h2">
                      <CalendarIcon color="green" style={{ marginRight: 15 }} />
                      {order.finished_date ? `Finalizada - ${formatDate(order.finished_date)}` : 'Sem'}
                    </Typography>
                    )}
                    {order.canceled_date
                    && (
                    <Typography title="Data Cancelada" gutterBottom variant="h6" component="h2">
                      <CalendarIcon color="red" style={{ marginRight: 15 }} />
                      {order.canceled_date ? `Cancelada - ${formatDate(order.canceled_date)}` : 'Sem'}
                    </Typography>
                    )}
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button
                    onClick={() => { buttonEdit(order.id); }}
                    title="Editar"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    <EditIcon />
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <TablePagination
        component="div"
        count={orders.meta.total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[]}
      />
    </>
  );
};

HistoryListResults.propTypes = {
  orders: PropTypes.array.isRequired,
  paginate: PropTypes.array.isRequired
};

export default HistoryListResults;
