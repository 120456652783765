function formatMoney(number) {
  if (number === null || number === undefined) {
    // eslint-disable-next-line no-param-reassign
    number = 0;
  }

  const formatoMoeda = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return formatoMoeda.format(number);
}

module.exports = formatMoney;
