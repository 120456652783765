import {
  React,
  useState,
  createContext,
  useContext
} from 'react';
import api from 'src/services/api';
import { SiteContext } from './SiteContext';

export const ConfigContext = createContext();

const ConfigProvider = (prop) => {
  const baseUrl = 'user/config';
  const { children } = prop;
  const [config, setConfig] = useState(null);
  const {
    notifyMessage,
    setNotifyMessage,
    loading,
    setLoading
  } = useContext(SiteContext);

  const closeNotifyMessage = () => {
    setNotifyMessage({
      ...notifyMessage,
      open: false
    });
  };

  const notifyMessageSave = (action) => {
    setNotifyMessage({
      text: `Registro ${action} com Sucesso !`,
      type: 'success',
      open: true
    });
  };

  const notifyMessageError = (action, err) => {
    setNotifyMessage({
      text: `Erro ao ${action} =( ${err}`,
      type: 'error',
      open: true
    });
  };

  const getConfig = async (id) => {
    setLoading(true);
    await api.get(`${baseUrl}/${id}`)
      .then((response) => {
        setConfig(response.data.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const updateConfig = async (id, data) => {
    setLoading(true);
    await api.post(`${baseUrl}/${id}`, data)
      .then((response) => {
        setConfig(response.data.data);
        setLoading(false);
        notifyMessageSave('Atualizado');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Atualizar', err);
        return false;
      });
  };

  return (
    <ConfigContext.Provider
      value={{
        config,
        loading,
        updateConfig,
        setConfig,
        getConfig,
        setNotifyMessage,
        closeNotifyMessage,
        notifyMessageError,
        notifyMessageSave
      }}
    >
      { children }
    </ConfigContext.Provider>

  );
};

export default ConfigProvider;
