import moment from 'moment';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {
  Eye as EyeIcon,
} from 'react-feather';
import { useState } from 'react';
import WhatsModal from '../modal/WhatsModal';

const OrdersExpired = (props) => {
  const navigate = useNavigate();
  const { orders } = props;

  const [messageModal, setMessageModal] = useState({
    open: false,
    phones: [],
    order_id: '',
  });

  const sendMessage = (data) => {
    const phone = data.whatsapp;
    const link = `http://service-control.flexpix.com.br/order/${data.order_id}`;
    window.open(`https://web.whatsapp.com/send?phone=55${phone}&text=${data.message} - veja os detalhes do orçamento ${link}`);
  };

  const openModalMessage = (id, phones) => {
    setMessageModal({
      open: true,
      order_id: id,
      phones,
      confirm: (data) => { sendMessage(data); }
    });
  };

  const viewOrder = (id) => {
    navigate(`/app/order/${id}`, { replace: true });
  };
  return (
    <Card {...props}>
      <CardHeader title="Ordens com pagamento pendente" />
      <Divider />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Numero
                </TableCell>
                <TableCell>
                  Produto
                </TableCell>
                <TableCell>
                  Cliente
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
                <TableCell>
                  Data Finalizada
                </TableCell>
                <TableCell>
                  Dias Expirada
                </TableCell>
                <TableCell>
                  Enviar Menssagem
                </TableCell>
                <TableCell>
                  Visualizar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => (
                <TableRow
                  hover
                  key={order.id}
                >
                  <TableCell>
                    {order.id}
                  </TableCell>
                  <TableCell>
                    {order.product.name}
                  </TableCell>
                  <TableCell>
                    {order.client.name}
                  </TableCell>
                  <TableCell>
                    <Chip
                      color="primary"
                      label={order.status_details}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    {moment(order.ready_date).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      label={order.expired_days}
                      color="secondary"
                    />
                  </TableCell>
                  <TableCell>
                    <Button disabled={!order.client.whatsapp.length > 0} onClick={() => { openModalMessage(order.id, order.client.whatsapp); }}>
                      <WhatsAppIcon />
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => { viewOrder(order.id); }}>
                      <EyeIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <WhatsModal modal={messageModal} />
      </PerfectScrollbar>
    </Card>
  );
};

OrdersExpired.propTypes = {
  orders: PropTypes.array.isRequired,
};

export default OrdersExpired;
