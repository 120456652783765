import { Link as RouterLink, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { useContext, useState } from 'react';
import { UserContext } from 'src/context/UserContext';
import { SiteContext } from 'src/context/SiteContext';

const ChangePassword = () => {
  const { token } = useParams();
  const { expiration } = useParams();
  const { changePassword } = useContext(UserContext);
  const { setNotifyMessage } = useContext(SiteContext);
  const [values, setValues] = useState({
    password: '',
    repeat: '',
    isSubmitting: false,
  });

  const validate = () => {
    if (values.repeat !== values.password) {
      setNotifyMessage({
        text: 'A senha e a confirmação precisão ser iguais',
        type: 'error',
        open: true,
      });

      setValues({
        ...values,
        isSubmitting: false
      });

      return false;
    }

    return true;
  };

  const change = async (data) => {
    if (validate()) {
      const loginData = await changePassword(data);
      if (loginData) {
        setNotifyMessage({
          text: 'Alteração realizada com sucesso, aguarde ...',
          type: 'success',
          open: true,
        });
      } else {
        setNotifyMessage({
          text: 'Erro ao realizar o alteração da senha',
          type: 'error',
          open: true,
        });
        setValues({
          ...values,
          isSubmitting: false
        });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      isSubmitting: true
    });
    const data = {
      token,
      password: values.password
    };
    change(data);
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <>
      <Helmet>
        <title>
          {`ChangePassword | ${process.env.REACT_APP_NAME}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <>
          <Container maxWidth="sm">
            <form onSubmit={handleSubmit}>
              <Box sx={{ mb: 3 }}>
                <Typography
                  color="textPrimary"
                  style={{ textAlign: 'center' }}
                  variant="h2"
                >
                  Alteração de Senha
                </Typography>
              </Box>
              <TextField
                fullWidth
                label="Senha"
                margin="normal"
                name="password"
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
              <TextField
                fullWidth
                label="Repita a Senha"
                margin="normal"
                name="repeat"
                onChange={handleChange}
                type="password"
                value={values.repeat}
                variant="outlined"
              />
              {expiration
                ? (
                  <Alert severity="warning">Sua sessão expirou, faça login novamente</Alert>
                )
                : <></>}
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  disabled={values.isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Alterar
                </Button>
              </Box>
              <Typography
                color="textSecondary"
                variant="body1"
              >
                <Link
                  component={RouterLink}
                  to="/"
                  variant="h6"
                >
                  Ir para Login
                </Link>
              </Typography>
            </form>
          </Container>
        </>
      </Box>
    </>
  );
};

export default ChangePassword;
