import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import {
  Avatar, Badge, Divider, Grid, IconButton, Menu, MenuItem, Typography
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { NotificationsContext } from 'src/context/NotificationsContext';
import { useNavigate } from 'react-router-dom';

const HeaderNotification = () => {
  const {
    notifications,
    getNotifications,
  } = useContext(NotificationsContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirectToOrder = (id) => {
    navigate(`/app/order/${id}`, { replace: true });
  };

  const redirectNotification = (type, id) => {
    if (type === 'O') {
      redirectToOrder(id);
    }
  };

  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <Badge
          badgeContent={notifications.length}
          color="error"
        >
          <NotificationsIcon />
        </Badge>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{
            marginTop: 40,
            marginLeft: -60
          }}
        >
          {notifications.map((notification) => {
            let image;
            if (notification.type === 'O') {
              image = notification.reference.product.image ? notification.reference.product.image : '/image/no-image.png';
            }

            return (
              <>
                <MenuItem onClick={() => { redirectNotification(notification.type, notification.reference_id); }}>
                  <Grid
                    container
                    style={{
                      maxWidth: 280,
                      maxHeight: 500,
                      padding: 5,
                    }}
                  >
                    <Grid
                      item
                      container
                      xs={3}
                      alignContent="center"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Avatar
                        alt="Remy Sharp"
                        src={image}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={9}
                      alignItems="center"
                      textAlign="left"
                      p={2}
                    >
                      <Grid item xs={12}>
                        <Typography style={{ whiteSpace: 'pre-line' }} variant="subtitle1" gutterBottom>
                          {notification.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography style={{ whiteSpace: 'pre-line' }} variant="overline" display="block" gutterBottom>
                          {notification.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </MenuItem>
                <Divider light />
              </>
            );
          })}
        </Menu>
      </IconButton>
    </>
  );
};

export default HeaderNotification;
