/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import OrderServiceListResults from 'src/components/order/OrderServicesListResult';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import {
  Trash2 as TrashIcon,
  Save as SaveIcon,
  PlusCircle as PlusIcon,
  Check as ChekIcon,
  Download as DownloadIcon
} from 'react-feather';
import moment from 'moment';
import ServiceModal from '../modal/ServiceModal';
import CancelOrderModal from '../modal/CancelOrderModal';
import ConfirmModal from '../modal/ConfirmModal';

const OrderProfileDetails = (props) => {
  const myRef = useRef();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const toDay = new Date();
  const [clientsSearch, setClientsSearch] = useState([]);
  const [productsSearch, setProductsSearch] = useState([]);
  const [clientValidate, setClientValidate] = useState({
    client_id: null,
    error: ''
  });
  const [productValidate, setProductValidate] = useState('');

  const scrollTop = () => {
    if (myRef.current) {
      myRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const formatDate = (value) => {
    if (value) {
      const date = new Date(value);
      return moment(date).format('YYYY-MM-DDTHH:mm');
    }
    return value;
  };

  const [values, setValues] = useState({
    product_id: '',
    order_number: '',
    productObject: null,
    authorization_withdrawal: '',
    technician_id: '',
    status: '',
    entry_date: formatDate(toDay),
    approved_budget_date: '',
    canceled_order_date: '',
    finalized_budget_date: '',
    finished_order_date: '',
    running_service_date: '',
    service_completed_date: '',
    awaiting_withdrawal_date: '',
    reason_canceled: '',
    observation: '',
  });

  const [serviceModal, setServiceModal] = useState({
    open: false,
    service: null,
    order_id: null,
    title: 'Cadastrar serviços',
  });

  const [cancelOrderModal, setCancelOrderModal] = useState({
    open: false,
    service: null,
    order_id: null,
    title: 'Cadastrar serviços',
  });

  const [confirmOrderModal, setConfirmOrderModal] = useState({
    open: false,
    title: '',
    descriotion: '',
  });

  const {
    productId,
    products,
    product,
    status,
    order,
    saveform,
    updateform,
    technicians,
    deleteService,
    reload,
    getService,
    saveService,
    updateService,
    setChangeStatusOrder,
    setCancel,
    clients,
    getProduct,
    getProductsByClient,
  } = props;

  useEffect(() => {
    setDisabled(false);
  }, []);

  useEffect(() => {
    if (clients && clients.data) {
      setClientsSearch(clients.data);
    }
  }, [clients]);

  useEffect(() => {
    if (products && products.data) {
      setProductsSearch(products.data);
    }
  }, [products]);

  useEffect(() => {
    if (order) {
      setValues({
        product_id: order.product.id,
        authorization_withdrawal: order.authorization_withdrawal,
        technician_id: order.technician ? order.technician.id : null,
        status: order.status,
        order_number: order.order_number,
        execute_date: order.execute_date,
        entry_date: formatDate(order.entry_date),
        awaiting_withdrawal_date: formatDate(order.awaiting_withdrawal_date),
        approved_budget_date: formatDate(order.approved_budget_date),
        finalized_budget_date: formatDate(order.finalized_budget_date),
        canceled_order_date: formatDate(order.canceled_order_date),
        finished_order_date: formatDate(order.finished_order_date),
        running_service_date: formatDate(order.running_service_date),
        service_completed_date: formatDate(order.service_completed_date),
        labor: order.labor,
        amount_order: order.amount_order,
        paid_out: order.paid_out,
        amount_services: order.amount_services,
        amount_aproved_services: order.amount_aproved_services,
        observation: order.observation,
        reason_canceled: order.reason_canceled,
      });
      if (order.status === 'C' || order.status === 'F') {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    } else {
      setValues({
        product_id: '',
        productObject: null,
        authorization_withdrawal: '',
        technician_id: '',
        status: '',
        entry_date: formatDate(toDay),
        reason_canceled: '',
        observation: '',
      });
    }
  }, [order]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  // eslint-disable-next-line consistent-return
  const validateFields = () => {
    if (productId) {
      return true;
    }
    let isValid = true;
    if (!clientValidate.client_id) {
      setClientValidate({
        ...clientValidate,
        error: 'Selecione o Cliente da Ordem'
      });
      scrollTop();
      isValid = false;
    } else {
      setClientValidate({
        ...clientValidate,
        error: ''
      });
    }

    if (!values.product_id) {
      setProductValidate('Selecione o Produto da Ordem');
      isValid = false;
      scrollTop();
    } else {
      setProductValidate('');
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (order) {
      await updateform(order.id, values);
    } else {
      const valid = validateFields();
      if (valid) {
        const save = await saveform(values);
        if (save) {
          navigate('/app/orders', { replace: true });
        }
      }
    }
  };

  const handleSeeClient = () => {
    navigate(`/app/client/${order.client.id}`, { replace: true });
  };

  const handleClientItemSelecionado = async (event, clientChange) => {
    if (clientChange) {
      setClientValidate({
        error: '',
        client_id: clientChange.id,
      });
      await getProductsByClient(clientChange.id);
    } else {
      setProductsSearch([]);
    }
  };

  const handleProductItemSelecionado = async (event, productChange) => {
    if (productChange) {
      setValues({
        ...values,
        product_id: productChange.id,
        productObject: productChange
      });
      setProductValidate('');
    } else {
      setValues({
        ...values,
        product_id: null,
        productObject: null
      });
    }
  };

  const reloadData = async () => {
    await reload(order.id);
  };

  const confirmModalService = async (data) => {
    if (data.id) {
      await updateService(data.id, data);
    } else {
      await saveService(data);
    }
    await reloadData();
    setServiceModal({ open: false });
  };

  const addService = () => {
    setServiceModal(
      {
        ...serviceModal,
        open: true,
        title: 'Cadastrar serviços',
        service: null,
        order_id: order.id,
        cancel: () => { },
        confirm: (data) => { confirmModalService(data); }
      }
    );
  };

  const editService = async (id) => {
    const service = await getService(id);
    if (service) {
      setServiceModal(
        {
          ...serviceModal,
          disabled,
          service,
          title: 'Editar serviços',
          open: true,
          order_id: order.id,
          cancel: () => { },
          confirm: (data) => { confirmModalService(data); }
        }
      );
    }
  };

  const confirmCancelOrderModal = async (data) => {
    const dataUpdate = {
      ...values,
      reason_canceled: data.reason_canceled,
      status: 'C'
    };
    await setCancel(order.id, dataUpdate);
    setCancelOrderModal({
      open: false
    });
  };

  const cancelModal = () => {
    setCancelOrderModal(
      {
        open: true,
        cancel: () => { },
        confirm: (data) => { confirmCancelOrderModal(data); }
      }
    );
  };

  const confirmModalStatus = async (statusOrder) => {
    await setChangeStatusOrder(order.id, statusOrder);
    setConfirmOrderModal({
      open: false
    });
  };

  // eslint-disable-next-line no-shadow
  const confirmModal = (status) => {
    let title;
    let description;

    switch (status) {
      case 'SC':
        title = 'Marcar como Serviço Finalizado';
        description = 'Deseja colocar esta order como status Serviços Finalizados assim como todos os seus serviços aprovados ?';
        break;
      case 'FO':
        title = 'Marcar como Order Finalizada';
        description = 'Deseja colocar esta order como status Order Finalizado assim como todos os seus serviços aprovados ?';
        break;
      case 'AW':
        title = 'Marcar como Aguardando Retirada';
        description = 'Deseja colocar esta order como status Aguardando Retirada do Cliente e finalizar todos os serviços aprovados ?';
        break;

      default:
        break;
    }

    setConfirmOrderModal(
      {
        open: true,
        title,
        description,
        cancel: () => { },
        // eslint-disable-next-line no-unused-expressions
        confirm: () => { confirmModalStatus(status); }
      }
    );
  };

  useEffect(async () => {
    if (productId) {
      await getProduct(productId);
      setValues({
        ...values,
        product_id: productId
      });
    }
  }, []);

  // eslint-disable-next-line consistent-return
  const bottonsFunction = () => {
    if (order) {
      if (order.status === 'AB' || order.status === 'RS') {
        return (
          <>
            <Grid item xs={12} lg={3} md={3} p={1}>
              <Button
                color="error"
                sx={{ width: { xs: '100%', md: 'auto' } }}
                variant="contained"
                type="button"
                onClick={cancelModal}
              >
                <TrashIcon style={{ marginRight: 10 }} />
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={12} lg={3} md={3} p={1}>
              <Button
                color="info"
                sx={{ width: { xs: '100%', md: 'auto' } }}
                variant="contained"
                type="button"
                onClick={() => { confirmModal('SC'); }}
              >
                <ChekIcon style={{ marginRight: 10 }} />
                Finalizar Serviços
              </Button>
            </Grid>
            <Grid item xs={12} lg={3} md={3} p={1}>
              <Button
                color="success"
                sx={{ width: { xs: '100%', md: 'auto' } }}
                variant="contained"
                type="button"
                style={{ color: '#fff' }}
                onClick={() => { confirmModal('FO'); }}
              >
                <DownloadIcon style={{ marginRight: 10 }} />
                Finalizar Ordem
              </Button>
            </Grid>
            <Grid item xs={12} lg={3} md={3} p={1}>
              <Button
                color="primary"
                sx={{ width: { xs: '100%', md: 'auto' } }}
                variant="contained"
                type="submit"
              >
                <SaveIcon style={{ marginRight: 10 }} />
                Salvar
              </Button>
            </Grid>
          </>
        );
      }

      if (order.status === 'SC') {
        return (
          <>
            <Grid item xs={12} lg={4} md={4} p={1}>
              <Button
                color="error"
                sx={{ width: { xs: '100%', md: 'auto' } }}
                variant="contained"
                type="button"
                onClick={cancelModal}
              >
                <TrashIcon style={{ marginRight: 10 }} />
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={12} lg={4} md={4} p={1}>
              <Button
                color="info"
                sx={{ width: { xs: '100%', md: 'auto' } }}
                variant="contained"
                type="button"
                onClick={() => { confirmModal('AW'); }}
              >
                <ChekIcon style={{ marginRight: 10 }} />
                Aguardando Retirada do Cliente
              </Button>
            </Grid>
            <Grid item xs={12} lg={4} md={4} p={1}>
              <Button
                color="primary"
                sx={{ width: { xs: '100%', md: 'auto' } }}
                variant="contained"
                type="submit"
              >
                <SaveIcon style={{ marginRight: 10 }} />
                Salvar
              </Button>
            </Grid>
          </>
        );
      }

      if (order.status === 'AW') {
        return (
          <>
            <Grid item xs={12} lg={4} md={4} p={1}>
              <Button
                color="error"
                sx={{ width: { xs: '100%', md: 'auto' } }}
                variant="contained"
                type="button"
                onClick={cancelModal}
              >
                <TrashIcon style={{ marginRight: 10 }} />
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={12} lg={3} md={3} p={1}>
              <Button
                color="info"
                sx={{ width: { xs: '100%', md: 'auto' } }}
                variant="contained"
                type="button"
                onClick={() => { confirmModal('FO'); }}
              >
                <ChekIcon style={{ marginRight: 10 }} />
                Finalizar Order
              </Button>
            </Grid>
            <Grid item xs={12} lg={4} md={4} p={1}>
              <Button
                color="primary"
                sx={{ width: { xs: '100%', md: 'auto' } }}
                variant="contained"
                type="submit"
              >
                <SaveIcon style={{ marginRight: 10 }} />
                Salvar
              </Button>
            </Grid>
          </>
        );
      }

      if (order.status === 'CO' || order.status === 'FO') {
        return (
          <>
          </>
        );
      }

      return (
        <Grid item xs={12} lg={2} md={2} p={1}>
          <Button
            color="primary"
            sx={{ width: { xs: '100%', md: 'auto' } }}
            variant="contained"
            type="submit"
          >
            <SaveIcon style={{ marginRight: 10 }} />
            Salvar
          </Button>
        </Grid>
      );
    }
    return (
      <Grid item xs={12} lg={2} md={2} p={1}>
        <Button
          color="primary"
          sx={{ width: { xs: '100%', md: 'auto' } }}
          variant="contained"
          type="submit"
        >
          <SaveIcon style={{ marginRight: 10 }} />
          Salvar
        </Button>
      </Grid>
    );
  };

  return (
    <form
      ref={myRef}
      autoComplete="off"
      onSubmit={handleSubmit}
      {...props}
    >
      {order && (
      <Typography variant="h2" p={2}>
        {' '}
        Numero da Ordem :
        {' '}
        {order.order_number}
      </Typography>
      )}
      {order && order.client.deleted
         && (
         <>
           <Alert severity="error"> Não é possível continuar com esta ordem até que o produto tenha um cliente, adicione um cliente ao produto ou cancele a ordem </Alert>
           <Alert severity="info">
             {' '}
             Para editar o produto
             {' '}
             <Button onClick={() => { navigate(`/app/product/${order.product.id}`, { replace: true }); }}> Clique Aqui</Button>
             {' '}
           </Alert>
         </>
         )}
      <Card>
        <CardHeader
          title="Orderm de Serviço"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            {!order
              ? (
                <>
                  <Grid
                    item
                    container
                    md={6}
                    lg={6}
                    xs={12}
                  >
                    <>
                      {!productId
                        ? (
                          <Autocomplete
                            id="auto-client"
                            fullWidth
                            options={clientsSearch}
                            onChange={handleClientItemSelecionado}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                {...params}
                                label="Cliente"
                                variant="outlined"
                                error={clientValidate.error}
                                helperText={clientValidate.error ? clientValidate.error : ''}
                              />
                            )}
                          />
                        ) : (
                          <>

                            { product && (
                            <TextField
                              fullWidth
                              disable
                              value={product.client.name}
                            />
                            )}
                          </>
                        )}
                    </>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    lg={6}
                    xs={12}
                  >
                    {!productId
                      ? (
                        <Autocomplete
                          id="auto-product"
                          fullWidth
                          required
                          name="productName"
                          options={productsSearch}
                          value={values.productObject}
                          onChange={handleProductItemSelecionado}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              label="Produtos"
                              variant="outlined"
                              error={productValidate}
                              helperText={productValidate || ''}
                            />
                          )}
                        />
                      ) : (
                        <>
                          { product && (
                          <TextField
                            fullWidth
                            disable
                            value={product.name}
                          />
                          )}
                        </>
                      )}
                  </Grid>
                </>
              )
              : (
                <>
                  <Grid
                    item
                    md={6}
                    lg={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      disabled={disabled}
                      label="Cliente"
                      name="clientName"
                      value={order.client.name}
                      variant="outlined"
                    />
                    <Button onClick={handleSeeClient}>Ver Cliente</Button>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    lg={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      disabled={disabled}
                      label="Produto"
                      name="productName"
                      value={order.product.name}
                      variant="outlined"
                    />
                  </Grid>

                </>
              )}
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <TextField
                fullWidth
                disabled={disabled}
                label="Status"
                name="status"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.status}
                variant="outlined"
              >
                <option />
                {Object.entries(status).map((option) => (
                  <option
                    key={option[0]}
                    value={option[0]}
                  >
                    {option[1]}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <TextField
                fullWidth
                disabled={disabled}
                label="Técnico"
                name="technician_id"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={values.technician_id}
                variant="outlined"
              >
                <option />
                {technicians && technicians.data && technicians.data.map((option) => (
                  <option
                    key={option.id}
                    value={option.id}
                  >
                    {option.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <TextField
                fullWidth
                disabled={disabled}
                label="Número da Ordem de Serviço"
                name="order_number"
                required
                onChange={handleChange}
                value={values.order_number}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <TextField
                fullWidth
                disabled={disabled}
                label="Autorização para Retirada"
                name="authorization_withdrawal"
                onChange={handleChange}
                value={values.authorization_withdrawal}
                variant="outlined"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid
                item
                md={6}
                lg={6}
                xs={12}
                mb={2}
              >
                <TextField
                  fullWidth
                  disabled={disabled}
                  label="Entrada da Order"
                  name="entry_date"
                  type="datetime-local"
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.entry_date}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                lg={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  disabled
                  label="Data Fim Orçamento"
                  name="finalized_budget_date"
                  type="datetime-local"
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.finalized_budget_date}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                lg={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  disabled
                  label="Data Orçamento Aprovado"
                  name="approved_budget_date"
                  type="datetime-local"
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.approved_budget_date}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                lg={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  disabled
                  label="Inicio do Serviço"
                  name="running_service_date"
                  type="datetime-local"
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.running_service_date}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                lg={6}
                xs={12}
                mt={2}
              >
                <TextField
                  fullWidth
                  disabled
                  label="Serviço Pronto"
                  name="service_completed_date"
                  type="datetime-local"
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.service_completed_date}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                lg={6}
                xs={12}
                mt={2}
              >
                <TextField
                  fullWidth
                  disabled
                  label="Data Aguardando Cliente"
                  name="awaiting_withdrawal_date"
                  type="datetime-local"
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.awaiting_withdrawal_date}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                lg={6}
                xs={12}
                mt={2}
              >
                <TextField
                  fullWidth
                  disabled
                  label="Data Order Finalizada"
                  name="finished_order_date"
                  type="datetime-local"
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.finished_order_date}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            {values.status === 'C'
              ? (
                <>
                  <Grid
                    item
                    style={{
                      border: '1px solid #E25141',
                      backgroundColor: '#f3c7cb',
                      borderRadius: 5
                    }}
                    ml={3}
                    mt={3}
                    pb={3}
                    pr={3}
                    xs={12}
                  >
                    <Grid
                      item
                      md={6}
                      lg={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        disabled={disabled}
                        label="Motivo do Cancelamento"
                        name="reason_canceled"
                        onChange={handleChange}
                        multiline
                        rows={4}
                        value={values.reason_canceled}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid
                      item
                      md={6}
                      lg={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        disabled
                        label="Cancelado do Serviço"
                        name="canceled_date"
                        type="datetime-local"
                        onChange={handleChange}
                        required
                        value={values.canceled_date}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </>
              )
              : <></> }
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                disabled={disabled}
                label="Observação"
                name="observation"
                onChange={handleChange}
                multiline
                rows={4}
                value={values.observation}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />

        {order
          ? (
            <>
              <Grid container style={{ padding: 20 }}>
                <Grid lg={12} md={12} xs={12} container style={{ paddingBottom: 20 }}>
                  <Grid lg={10} md={10} xs={12}>
                    <Typography variant="h1" component="h2">
                      Serviços
                    </Typography>
                  </Grid>
                  <Grid
                    lg={2}
                    md={2}
                    xs={12}
                    textAlign="right"
                  >
                    { order && !order.client.deleted && order.status !== 'C' && order.status !== 'F'
                      ? (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={addService}
                        >
                          <PlusIcon style={{ marginRight: 10 }} />
                          Adicionar
                        </Button>
                      )
                      : <></> }
                  </Grid>
                </Grid>
                <Grid xs={12} style={{ paddingBottom: 20 }}>
                  <OrderServiceListResults services={order.services} exclude={deleteService} edit={editService} reload={reloadData} disabled={disabled} />
                </Grid>
                <Grid lg={6} md={6} xs={12} p={1}>
                  <Alert variant="filled" severity="info" icon={false}>
                    <Typography variant="h3" component="h2">
                      {'Total : R$ '}
                      {values.amount_services}
                    </Typography>
                  </Alert>
                </Grid>
                <Grid lg={6} md={6} xs={12} p={1}>
                  <Alert variant="filled" severity="success" icon={false}>
                    <Typography variant="h3" component="h2">
                      {'Aprovado : R$ '}
                      {values.amount_aproved_services}
                    </Typography>
                  </Alert>
                </Grid>
              </Grid>
            </>
          )
          : <></> }

        <Divider />
        <Grid container style={{ padding: 20 }}>
          <Grid xs={12} style={{ paddingBottom: 20 }}>
            <Typography variant="h1" component="h2">
              Financeiro
            </Typography>
          </Grid>
          <Grid xs={12} m={1}>
            <CurrencyTextField
              label="Valor Mão de Obra"
              disabled={disabled}
              variant="outlined"
              value={values.labor}
              currencySymbol="R$"
              minimumValue="0"
              fullWidth
              outputFormat="string"
              decimalCharacter=","
              digitGroupSeparator="."
              onChange={(event, value) => {
                setValues({
                  ...values,
                  labor: value
                });
              }}
            />
          </Grid>
          <Grid xs={12} m={1}>
            <CurrencyTextField
              label="Valor Aprovado"
              disabled
              variant="outlined"
              value={values.amount_aproved_services}
              currencySymbol="R$"
              minimumValue="0"
              fullWidth
              outputFormat="string"
              decimalCharacter=","
              digitGroupSeparator="."
            />
          </Grid>
          <Grid xs={12} m={1}>
            <CurrencyTextField
              label="Valor Total Ordem"
              disabled
              variant="outlined"
              value={values.amount_order}
              currencySymbol="R$"
              minimumValue="0"
              fullWidth
              outputFormat="string"
              decimalCharacter=","
              digitGroupSeparator="."
            />
          </Grid>
        </Grid>
        <Divider />
        {
          !disabled
            ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <Grid container alignContent="right" alignItems="right" justifyContent="right">
                  {bottonsFunction()}
                </Grid>
              </Box>
            )
            : <></>
        }
        <ServiceModal modal={serviceModal} />
        <CancelOrderModal modal={cancelOrderModal} />
        <ConfirmModal modal={confirmOrderModal} />
      </Card>
    </form>
  );
};

OrderProfileDetails.propTypes = {
  productId: PropTypes.number,
  order: PropTypes.object,
  products: PropTypes.object,
  status: PropTypes.object,
  technicians: PropTypes.object,
  updateform: PropTypes.func.isRequired,
  saveform: PropTypes.func.isRequired,
  deleteService: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired,
  getService: PropTypes.func.isRequired,
  saveService: PropTypes.func.isRequired,
  updateService: PropTypes.func.isRequired,
  setChangeStatusOrder: PropTypes.func.isRequired,
  setCancel: PropTypes.func.isRequired,
  clients: PropTypes.object,
  getProductsByClient: PropTypes.func.isRequired,
  getProduct: PropTypes.func,
  product: PropTypes.object
};

export default OrderProfileDetails;
