import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Grid,
  MenuItem
} from '@mui/material';
import { Search as SearchIcon } from 'react-feather';
import PropTypes from 'prop-types';
import { useState } from 'react';

const OrderListToolbar = (props) => {
  const { search, status, id } = props;
  const [values, setValues] = useState({
    client_id: id,
    param: '',
    status: 'A'
  });

  const handleSearch = async () => {
    await search(values);
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      />
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid xs={12} md={6} lg={6} p={1}>
                <Box sx={{ maxWidth: 500 }}>
                  <TextField
                    fullWidth
                    onChange={handleChange}
                    name="param"
                    value={values.search}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Pesquisar"
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid xs={12} md={4} lg={4} p={1}>
                <TextField
                  id="standard-select-currency"
                  select
                  fullWidth
                  label="Status"
                  value={values.status}
                  name="status"
                  onChange={handleChange}
                >
                  <MenuItem key={0} value="A">
                    Todos
                  </MenuItem>
                  {Object.entries(status).map((option) => (
                    <MenuItem key={option[0]} value={option[0]}>
                      {option[1]}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid xs={12} md={2} lg={2} p={1}>
                <Button fullWidth style={{ padding: 15, float: 'right' }} color="primary" variant="contained" onClick={handleSearch}><SearchIcon /></Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

OrderListToolbar.propTypes = {
  search: PropTypes.isRequired,
  status: PropTypes.isRequired,
  id: PropTypes.isRequired,
};

export default OrderListToolbar;
